import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Space, Table, Tabs, Tooltip } from 'antd'
import { creditCardSVG } from 'assets/svg/creditCard'
import { useSelector } from 'hooks/useSelector'
import { log } from 'logger'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { theme } from 'theme'
import { editGreySVG } from '../../assets/svg/edit'
import { greyInfoSVG } from '../../assets/svg/greyInfo'
import { magnifyBlackSVG } from '../../assets/svg/magnify'
import { plusSVG } from '../../assets/svg/plus'
import { ButtonIcon, ButtonIconCircle, ButtonModal } from '../../atom/button'
import { ResponsiveTable } from '../../atom/responsive-table'
import { TableWrapper } from '../../atom/table-wrapper'
import { ButtonTextSpacing } from '../../atom/user-invite'
import {
  CustomBox,
  DriverSvgSpacing,
  EditButtonWrapper,
  InviteButtonCircleWrapper,
  InviteButtonWrapper,
  LineBreak,
  MultiTenantMobileStyling,
  MultiTenantStyling,
  SearchButtonContainer,
  SearchButtonWrapper,
  TableStyling,
  TabsButtonWrapper,
  TabsWrapper,
  UserSelectorStyling,
  UserTitleMobileWrapper,
} from '../../atom/users-page-redesign-styles'
import { AlertError } from '../../components/error'
import { PlansRedesign } from '../../components/plans/plans-redesign'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'
import { NoIconSearch } from '../../components/redesign/no-icon-search'
import { UserSelectorNoTagRedesign } from '../../components/selector/user-selector-no-tag'
import { SubscriptionsRedesign } from '../../components/subscriptions/subscriptions-redesign'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../models/error'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import {
  AugmentedUser,
  DashboardAccessType,
  isManagerToTenants,
  toAugmentedUser,
  User,
  UserInviteSuccess,
  UserWallet,
} from '../../models/user'
import { FindTenants } from '../../services/data-provider/tenants'
import { GetUserInvitations, GetUsers } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import { UserRFIDForm } from './modal/user-rfid-form'
import { UserEditForm } from './user-edit-redesign'
import { UserInvitationRedesign } from './user-invitation-redesign'
import UsersCollapse from './users-collapse'

export const UsersRedesignBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const responsiveHeaderTitleTabletAndLaptop = useMediaQuery({ maxWidth: 1233, minWidth: 0 })
  const showFullInviteButtonTabletAndLaptop = useMediaQuery({ maxWidth: 1233, minWidth: 856 })
  const showFullInviteButtonMobileAndTablet = useMediaQuery({ maxWidth: 758, minWidth: 0 })
  const responsiveLineBreak = useMediaQuery({ maxWidth: 1233, minWidth: 993 })
  const responsiveSearchButtonForMobile = useMediaQuery({ maxWidth: 492, minWidth: 0 })
  const showCollapsedTable = useMediaQuery({ maxWidth: 767, minWidth: 0 })
  const { currentUser, selectedTenant, IsLaptop, IsDesktop, IsTablet, IsMobile, isMockUpEnabled } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [users, setUsers] = useState<AugmentedUser[]>([])
  const [term, setTerm] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<AugmentedUser>()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  //const [currentPage, setCurrentPage] = useState(1) //Commented out in case need in the future
  const [showInvitationForm, setShowInvitationForm] = useState(false)
  const [showEditUserNameForm, setEditUserNameForm] = useState(false)
  const [showRFIDForm, setShowRFIDForm] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [currentAugmentedUser, setCurrentAugmentedUser] = useState<AugmentedUser>()
  const [tab, setTab] = useState('overview')
  const [createPlanForm, setShowCreatePlanForm] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [subscriptionEmailSearch, setSubscriptionEmailSearch] = useState<UserWallet | undefined>()
  const [searchSubscriptions, setSearchSubscriptions] = useState(false)
  const {
    emailTxt,
    driverInfoTxt,
    driverTxt,
    inviteUser,
    tenantsTxt,
    createPlanText,
    subscribeUserText,
    userText,
    noAccessText,
    roleTxt,
    overviewText,
    plansText,
    subscriptionsText,
    guestUserText,
  } = useUserTranslation()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const isLaptop = IsLaptop()
  const { siteLabelCleaner } = useSelector()

  const fetchUsers = (page = 1) => {
    setLoading(true)
    setError(undefined)
    let pageNumber = 1
    //To handle event getting passed from Button onClick
    if (typeof page === 'number') {
      pageNumber = page
    }

    const showCurrentUserDataAtTop = !term && currentUser?.accesses && isManagerToTenants(currentUser.accesses)
    if (tenants.length === 0) {
      if (currentUser?.role === 'admin') {
        Promise.all([
          GetUserInvitations(pageNumber, isMockUpEnabled, term, undefined, undefined),
          GetUsers(pageNumber, isMockUpEnabled, term, undefined, undefined),
        ])
          .then(([invitationsResp, usersResp]) => {
            // Combine and sort the results
            const combinedData = [...invitationsResp.data, ...usersResp.data]
            setUsers(combinedData)
            // Assuming pagination should be based on total combined results
            setPagination({
              ...usersResp.pagination,
              totalEntries: usersResp.pagination.totalEntries + invitationsResp.pagination.totalEntries,
              perPage: usersResp.pagination.perPage + invitationsResp.pagination.perPage,
            })
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      } else {
        FindTenants(currentUser, false, isMockUpEnabled)
          .then((tenantsForUser) => {
            Promise.all([
              ...(showCurrentUserDataAtTop
                ? [GetUsers(1, isMockUpEnabled, currentUser?.email, undefined, undefined)]
                : []),
              GetUserInvitations(pageNumber, isMockUpEnabled, term, selectedTenant, tenantsForUser),
              GetUsers(pageNumber, isMockUpEnabled, term, selectedTenant, tenantsForUser),
            ])
              .then((responses) => {
                const [invitationsResp, usersResp] = showCurrentUserDataAtTop ? responses.slice(1) : responses
                const currentUserResp = showCurrentUserDataAtTop ? responses[0] : undefined

                const combinedData = [
                  ...(currentUserResp?.data || []),
                  ...invitationsResp.data,
                  ...usersResp.data.filter(({ email }) =>
                    showCurrentUserDataAtTop ? email !== currentUser?.email : true,
                  ),
                ]
                setUsers(combinedData)
                setPagination({
                  ...usersResp.pagination,
                  perPage: usersResp.pagination.perPage + invitationsResp.pagination.perPage,
                  totalEntries: usersResp.pagination.totalEntries + invitationsResp.pagination.totalEntries,
                })
              })
              .catch((error) => {
                setError(error)
              })
              .finally(() => setLoading(false))
          })
          .catch((err: SwtchError) => message.error(err.description))
      }
    } else {
      Promise.all([
        ...(showCurrentUserDataAtTop ? [GetUsers(1, isMockUpEnabled, currentUser?.email, undefined, undefined)] : []),
        GetUserInvitations(pageNumber, isMockUpEnabled, term, selectedTenant, tenants),
        GetUsers(pageNumber, isMockUpEnabled, term, selectedTenant, tenants),
      ])
        .then((responses) => {
          const [invitationsResp, usersResp] = showCurrentUserDataAtTop ? responses.slice(1) : responses
          const currentUserResp = showCurrentUserDataAtTop ? responses[0] : undefined

          const combinedData = [
            ...(currentUserResp?.data || []),
            ...invitationsResp.data,
            ...usersResp.data.filter(({ email }) => (showCurrentUserDataAtTop ? email !== currentUser?.email : true)),
          ]
          setUsers(combinedData)
          setPagination({
            ...usersResp.pagination,
            perPage: usersResp.pagination.perPage + invitationsResp.pagination.perPage,
            totalEntries: usersResp.pagination.totalEntries + invitationsResp.pagination.totalEntries,
          })
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagination])

  const tenantScope: TenantRef[] = selectedTenant
    ? [
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ]
    : tenants
  const hasTenantScope = tenantScope !== undefined

  useEffect(() => {
    if (selectedTenant) {
      setTenants([
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ])
    }
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
  }, [])

  const handleUserInvited = (newUser: User | UserInviteSuccess, isMockUpEnabled: boolean) => {
    setShowInvitationForm(false)
    if (isMockUpEnabled && 'role' in newUser) {
      return setUsers([...users, toAugmentedUser(newUser)])
    }
    return fetchUsers()
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    fetchUsers(page)
  }

  const renderDriverInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = siteLabelCleaner(tenants[0].combineName)
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsTxt}`
    }
    return `${driverInfoTxt} ${temp}`
  }

  const handleModifyBtn = (user: AugmentedUser) => {
    setSelectedUser(user)
    setEditUserNameForm(true)
  }

  const handleRFIDBtn = (user: AugmentedUser) => {
    setSelectedUser(user)
    setShowRFIDForm(true)
  }

  const handleAddUser = () => {
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
    // setAddUserToTenant(currentAugmentedUserTemp)
    setShowInvitationForm(true)
  }

  const renderAccess = (user: AugmentedUser) => {
    log('user', user)
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    }
    if (access === undefined) {
      access = 'none'
    }

    const style = user?.recordType === 'UserInvitation' ? { color: theme.colors.darkGray } : {}

    if (access === 'none') {
      return <div style={style}>None</div>
    } else if (access === 'manager') {
      return <div style={style}>Manager</div>
    } else if (access === 'viewer') {
      return <div style={style}>Viewer</div>
    } else if (access === 'Multi') {
      return <div style={style}>Multi</div>
    }
  }

  const columns: any = [
    {
      title: emailTxt,
      render: (user: User) => {
        if (user?.recordType === 'UserInvitation') {
          return <div style={{ color: theme.colors.darkGray }}>{user.email}</div>
        }
        return user.email
      },
    },
    {
      title: userText,
      render: (user: User) => {
        if (user?.recordType === 'UserInvitation') {
          return <div style={{ color: theme.colors.darkGray }}>{user.guest ? guestUserText : user.name}</div>
        }
        if (user.guest) {
          return guestUserText
        }
        return user.name
      },
    },
  ]

  log('hasTenantScope', hasTenantScope)
  log('tenants', tenants)
  if (hasTenantScope && tenants && tenants.length === 1) {
    columns.push(
      {
        title: (
          <>
            {driverTxt}
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
              placement="top"
              title={renderDriverInfoText()}
            >
              <DriverSvgSpacing>{greyInfoSVG}</DriverSvgSpacing>
            </Tooltip>
          </>
        ),
        key: 'driver',
        className: 'user-driver',
        render: ({ accessPlans, recordType }: AugmentedUser) => {
          if (!tenantScope) {
            return <></>
          }
          const currentUserTenantAccesses = tenants.map(({ id }) => Number(id))
          const accessPlanCurrentUserHasAccess = accessPlans?.filter(({ tenant_id }) =>
            currentUserTenantAccesses.includes(tenant_id),
          )
          if (accessPlanCurrentUserHasAccess?.length) {
            return (
              <div>
                <span style={recordType === 'UserInvitation' ? { color: theme.colors.darkGray } : {}}>
                  {accessPlanCurrentUserHasAccess.map((plan) => plan.name).join(', ')}
                </span>
              </div>
            )
          } else {
            return <div style={{ color: theme.colors.darkGray }}>{noAccessText}</div>
          }
        },
        width: '10%',
      },
      Table.EXPAND_COLUMN,
      {
        title: <div>{roleTxt}</div>,
        key: 'tenantAccess',
        render: (user: AugmentedUser) => renderAccess(user),
      },
      {
        title: '',
        key: 'action',
        render: (user: AugmentedUser) =>
          user.recordType === 'Person' && (
            <EditButtonWrapper>
              <ButtonModal
                type="link"
                style={{ paddingRight: '10px' }}
                icon={editGreySVG}
                click={() => handleModifyBtn(user)}
              />
              {currentUser?.role === 'admin' ||
              (currentUser?.accesses &&
                isManagerToTenants(currentUser?.accesses) &&
                user.email === currentUser.email) ? (
                <ButtonModal type="link" icon={creditCardSVG} click={() => handleRFIDBtn(user)} />
              ) : (
                <div style={{ width: '67px' }} />
              )}
            </EditButtonWrapper>
          ),
      },
    )
  } else if (currentUser?.role === 'admin' || currentUser?.role === 'user') {
    columns.push({
      title: '',
      key: 'action',
      render: (user: AugmentedUser) =>
        user.recordType === 'Person' && (
          <EditButtonWrapper>
            <ButtonModal
              type="link"
              style={{ paddingRight: '10px' }}
              icon={editGreySVG}
              click={() => handleModifyBtn(user)}
            />
            {currentUser?.role === 'admin' ||
            (currentUser?.accesses && isManagerToTenants(currentUser?.accesses) && user.email === currentUser.email) ? (
              <ButtonModal type="link" icon={creditCardSVG} click={() => handleRFIDBtn(user)} />
            ) : (
              <div style={{ width: '67px' }} />
            )}
          </EditButtonWrapper>
        ),
    })
  }
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTerm(e.target.value)
  }

  const handleSubscriptionEmailChange = (user: UserWallet | undefined) => setSubscriptionEmailSearch(user)

  const onSubmitSubscriptionSearch = () => setSearchSubscriptions(!searchSubscriptions)

  const handleTabOptions = () => {
    switch (tab) {
      case 'overview':
        return !responsiveHeaderTitleTabletAndLaptop ? (
          <Row>
            <div className="user-selector">
              <MultiTenantStyling>
                <MultiTenantSelector
                  longerWidth={true}
                  isCombineRight
                  noChangeOnFocus={true}
                  onOptionsChange={(tenants) => setTenants(tenants)}
                  onClear={() => setTenants([])}
                />
              </MultiTenantStyling>
              <LineBreak />
              <UserSelectorStyling>
                <SearchButtonContainer>
                  <NoIconSearch
                    svg={<></>}
                    placeholder={emailTxt}
                    onChange={handleEmailChange}
                    onPressEnter={fetchUsers}
                  />
                  <SearchButtonWrapper>
                    <ButtonIcon
                      icon={magnifyBlackSVG}
                      loading={loading}
                      disabled={loading}
                      fetchData={fetchUsers}
                      withinSearchBar
                    />
                  </SearchButtonWrapper>
                </SearchButtonContainer>
              </UserSelectorStyling>
            </div>
            <Space>
              <InviteButtonWrapper>
                <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
              </InviteButtonWrapper>
            </Space>
          </Row>
        ) : (
          <></>
        )
      case 'plans':
        return (
          <TabsButtonWrapper className="plans-button">
            <ButtonModal
              size="large"
              click={() => setShowCreatePlanForm(true)}
              icon={plusSVG}
              text={createPlanText}
              type="primary"
            />
          </TabsButtonWrapper>
        )
      case 'subscriptions':
        return (
          <TabsButtonWrapper className="subscriptions-header" isLaptop={isLaptop || isDesktop}>
            <div style={{ display: 'flex' }}>
              <UserSelectorNoTagRedesign
                onUserSelected={handleSubscriptionEmailChange}
                onSubmit={onSubmitSubscriptionSearch}
                dropdownClassName={'not-in-modal-selctor'}
              />
            </div>
            {isDesktop || isLaptop ? (
              <ButtonModal
                size="large"
                click={() => setShowInviteForm(true)}
                icon={plusSVG}
                text={subscribeUserText}
                type="primary"
              />
            ) : (
              <InviteButtonCircleWrapper>
                <ButtonIconCircle
                  icon={plusSVG}
                  disabled={false}
                  fetchData={() => setShowInviteForm(true)}
                  loading={false}
                  size="large"
                />
              </InviteButtonCircleWrapper>
            )}
          </TabsButtonWrapper>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    if (currentUser?.role !== 'admin') {
      return (
        <Row
          gutter={[8, 8]}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fafafa',
            padding: '20px 4px 21px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <MultiTenantStyling>
              <MultiTenantSelector
                longerWidth={true}
                isCombineRight
                noChangeOnFocus={true}
                onOptionsChange={(tenants) => setTenants(tenants)}
                onClear={() => setTenants([])}
                dropdownClassName={'not-in-modal-selector'}
              />
            </MultiTenantStyling>
            <LineBreak />
            <UserSelectorStyling>
              <SearchButtonContainer>
                <NoIconSearch
                  svg={<></>}
                  placeholder={emailTxt}
                  onChange={handleEmailChange}
                  onPressEnter={fetchUsers}
                />
                <SearchButtonWrapper>
                  <ButtonIcon
                    icon={magnifyBlackSVG}
                    loading={loading}
                    disabled={loading}
                    fetchData={fetchUsers}
                    withinSearchBar
                  />
                </SearchButtonWrapper>
              </SearchButtonContainer>
            </UserSelectorStyling>
          </div>
          <Space>
            <InviteButtonWrapper>
              <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
            </InviteButtonWrapper>
          </Space>
        </Row>
      )
    }
  }

  const renderColumns = (): any => {
    let currentAugmentedUser = currentUser && toAugmentedUser(currentUser)
    const tenantAccesses =
      currentAugmentedUser?.tenantPermission && Object.values(currentAugmentedUser?.tenantPermission)
    const isManager = tenantAccesses?.some((item) => item.access === 'manager')

    if ((currentAugmentedUser?.isAdmin || isManager) && !term) {
      return columns
        .filter((col: any) => col.key !== 'manageraddusertotenants')
        .filter((col: any) => col.key !== 'addusertotenants')
    }
    return term ? columns : columns.filter((col: any) => col.key !== 'addusertotenants')
  }

  const [loadedColumns, setLoadedColumns] = useState<any>(columns)
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: string]: boolean }>({})
  const [reload, setReload] = useState(1)

  useEffect(() => {
    const columns = renderColumns()
    setLoadedColumns(columns)
  }, [currentUser, term, tenants])

  useEffect(() => {
    if (loadedColumns.length > 0 && users.length > 0 && !loading) {
      users.forEach((record) => {
        rowExpandable(record as any)
      })
      setReload(reload + 1)
    }
  }, [loadedColumns, users, loading])

  const renderTitleMobile = () => {
    return (
      <>
        <UserTitleMobileWrapper isAdmin={currentUser?.role === 'admin'}>
          <MultiTenantMobileStyling>
            <MultiTenantSelector
              longerWidth={true}
              isCombineRight
              noChangeOnFocus={true}
              onOptionsChange={(tenants) => setTenants(tenants)}
              onClear={() => setTenants([])}
              dropdownClassName={'not-in-modal-selector'}
            />
          </MultiTenantMobileStyling>
        </UserTitleMobileWrapper>
        <div style={{ display: 'flex' }}>
          <MultiTenantMobileStyling>
            <NoIconSearch svg={<></>} placeholder={emailTxt} onChange={handleEmailChange} onPressEnter={fetchUsers} />
          </MultiTenantMobileStyling>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <Button
            style={{ width: `${responsiveSearchButtonForMobile ? '80%' : '90%'}` }}
            type="primary"
            onClick={() => {
              fetchUsers()
            }}
          >
            {magnifyBlackSVG}
            <ButtonTextSpacing>
              <span className="paragraph-02-regular">SEARCH</span>
            </ButtonTextSpacing>
          </Button>
          <InviteButtonCircleWrapper>
            <ButtonIconCircle icon={plusSVG} fetchData={handleAddUser} disabled={false} loading={false} size="large" />
          </InviteButtonCircleWrapper>
        </div>
      </>
    )
  }

  const renderTitleTablet = () => {
    let padding = ''
    if (currentUser?.role === 'admin') {
      padding = '0px 4px 21px'
    } else {
      padding = '20px 4px 21px'
    }

    return (
      <Row
        gutter={[8, 8]}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fafafa',
          padding: padding,
        }}
      >
        <div style={{ display: 'flex' }}>
          <MultiTenantStyling>
            <MultiTenantSelector
              longerWidth={true}
              isCombineRight
              noChangeOnFocus={true}
              onOptionsChange={(tenants) => setTenants(tenants)}
              onClear={() => setTenants([])}
            />
          </MultiTenantStyling>
          {responsiveLineBreak && <LineBreak isAdmin={currentUser?.role === 'admin'} />}
          <UserSelectorStyling tablet={true}>
            <SearchButtonContainer>
              <NoIconSearch svg={<></>} placeholder={emailTxt} onChange={handleEmailChange} onPressEnter={fetchUsers} />
              <SearchButtonWrapper>
                <ButtonIcon
                  icon={magnifyBlackSVG}
                  loading={loading}
                  disabled={loading}
                  fetchData={fetchUsers}
                  withinSearchBar
                />
              </SearchButtonWrapper>
            </SearchButtonContainer>
          </UserSelectorStyling>
        </div>
        {showFullInviteButtonTabletAndLaptop || showFullInviteButtonMobileAndTablet ? (
          <>
            <InviteButtonWrapper>
              <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
            </InviteButtonWrapper>
          </>
        ) : (
          <InviteButtonCircleWrapper>
            <ButtonIconCircle icon={plusSVG} fetchData={handleAddUser} disabled={false} loading={false} size="large" />
          </InviteButtonCircleWrapper>
        )}
      </Row>
    )
  }

  const onExpand = (expanded: boolean, record: TenantRef) => {
    setExpandableRowKeys({ ...expandableRowKeys, [record.id]: expanded })
  }

  const rowExpandable = (record: TenantRef) => {
    if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return true
    const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)

    const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight - 12 * 2 //considered padding
    const childElements = rowElement?.querySelectorAll('.custom-row-collapse-users-page > td > *')

    let expandable = false

    childElements?.forEach((el: Element) => {
      expandable = expandable || el.scrollHeight > currentRowHeight
    })
    return expandable
  }
  const usersTable = (
    <Row>
      <Col span={24}>
        <AlertError error={error} />
        <Col>
          {(isDesktop || isLaptop) && (
            <TableStyling usersPage>
              <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
                {responsiveHeaderTitleTabletAndLaptop ? renderTitleTablet() : renderTitle()}
                <ResponsiveTable
                  key={reload}
                  loading={loading}
                  size="small"
                  className="expandable-table"
                  pagination={{
                    position: ['bottomCenter'],
                    total: pagination?.totalEntries,
                    current: pagination?.currentPage,
                    pageSize: pagination?.perPage,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                  }}
                  dataSource={users}
                  columns={loadedColumns}
                  expandable={
                    hasTenantScope && tenants && tenants.length === 1
                      ? {
                          onExpand,
                          expandedRowRender: () => <></>,
                          rowExpandable,
                          expandIcon: ({ expandable, expanded, record, onExpand }) => {
                            return expandable ? (
                              expanded ? (
                                <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
                              ) : (
                                <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
                              )
                            ) : undefined
                          },
                        }
                      : undefined
                  }
                  rowClassName={(record) => (expandableRowKeys[record.id] ? '' : 'custom-row-collapse-users-page')}
                  rowKey="id"
                />
              </TableWrapper>
            </TableStyling>
          )}
          {isTablet && (
            <>
              {showCollapsedTable ? (
                <>
                  {renderTitleMobile()}
                  <UsersCollapse
                    loading={false}
                    users={users}
                    pagination={pagination}
                    onPaginationChange={onPaginationChange}
                    tenants={tenants}
                    setEditUserNameForm={setEditUserNameForm}
                    setSelectedUser={setSelectedUser}
                  />
                </>
              ) : (
                <TableStyling usersPage>
                  <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
                    <ResponsiveTable
                      title={showFullInviteButtonMobileAndTablet ? renderTitleMobile : renderTitleTablet}
                      loading={loading}
                      size="small"
                      pagination={{
                        position: ['bottomCenter'],
                        total: pagination?.totalEntries,
                        current: pagination?.currentPage,
                        pageSize: pagination?.perPage,
                        showSizeChanger: false,
                        onChange: onPaginationChange,
                      }}
                      dataSource={users}
                      columns={renderColumns()}
                      rowKey="id"
                    />
                  </TableWrapper>
                </TableStyling>
              )}
            </>
          )}
          {isMobile && (
            <>
              {renderTitleMobile()}
              <UsersCollapse
                loading={false}
                users={users}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                tenants={tenants}
                setEditUserNameForm={setEditUserNameForm}
                setSelectedUser={setSelectedUser}
              />
            </>
          )}
        </Col>
      </Col>
    </Row>
  )

  const handleUserEdit = () => {
    setEditUserNameForm(false)
    return fetchUsers()
  }

  return (
    <>
      <>
        <CustomBox isLaptop={isLaptop || isDesktop} showMobileHeader={showCollapsedTable}>
          {currentUser?.role === 'admin' ? (
            <TabsWrapper>
              <Tabs
                className="tabs-styling"
                defaultActiveKey="overview"
                onChange={(key) => setTab(key)}
                tabBarExtraContent={handleTabOptions()}
              >
                <Tabs.TabPane key="overview" tab={<span className="regular-cap">{overviewText}</span>}>
                  {usersTable}
                </Tabs.TabPane>
                <Tabs.TabPane key="plans" tab={<span className="regular-cap">{plansText}</span>}>
                  <PlansRedesign createPlanForm={createPlanForm} onCancel={() => setShowCreatePlanForm(false)} />
                </Tabs.TabPane>
                <Tabs.TabPane key="subscriptions" tab={<span className="regular-cap">{subscriptionsText}</span>}>
                  <SubscriptionsRedesign
                    searchUser={subscriptionEmailSearch}
                    searchSubscriptions={searchSubscriptions}
                    onUserInvited={() => setShowInviteForm(false)}
                    showInviteForm={showInviteForm}
                  />
                </Tabs.TabPane>
              </Tabs>
            </TabsWrapper>
          ) : (
            usersTable
          )}
        </CustomBox>
      </>
      {showInvitationForm && currentAugmentedUser && (
        <UserInvitationRedesign
          onCancel={(e: React.FormEvent<EventTarget>) => setShowInvitationForm(false)}
          onUserInvited={handleUserInvited}
        />
      )}

      {selectedUser && showEditUserNameForm && currentAugmentedUser && (
        <UserEditForm
          onCancel={() => setEditUserNameForm(false)}
          onUserEdit={handleUserEdit}
          selectedUser={selectedUser}
          user={currentAugmentedUser}
        />
      )}
      {showRFIDForm && selectedUser && (
        <UserRFIDForm onCancel={() => setShowRFIDForm(false)} selectedUser={selectedUser} />
      )}
    </>
  )
}
