import { theme } from '../../theme'

export const creditCardSVG = (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.875 7.375H18.125M1.875 8H18.125M4.375 12.375H9.375M4.375 14.25H6.875M3.75 16.75H16.25C16.7473 16.75 17.2242 16.5525 17.5758 16.2008C17.9275 15.8492 18.125 15.3723 18.125 14.875V6.125C18.125 5.62772 17.9275 5.15081 17.5758 4.79917C17.2242 4.44754 16.7473 4.25 16.25 4.25H3.75C3.25272 4.25 2.77581 4.44754 2.42417 4.79917C2.07254 5.15081 1.875 5.62772 1.875 6.125V14.875C1.875 15.3723 2.07254 15.8492 2.42417 16.2008C2.77581 16.5525 3.25272 16.75 3.75 16.75Z"
      stroke={theme.colors.darkGray}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
