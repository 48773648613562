import { List } from 'antd'

import TotalCard from 'atom/cards/TotalCard'
import { ListCardWrapper } from 'atom/list'

import { Financial } from 'models/transaction'
import { formatPrice } from 'helpers/number-format'
import { useFinancialTranslation } from 'hooks/translation/useFinancialTranslation'

interface FinancialProps {
  financial: Financial
}

const TransactionPanelTotal: React.FC<FinancialProps> = ({ financial }) => {
  const { taxText, subTotalText, totalText, localTaxText } = useFinancialTranslation()

  const { tax, subtotal, total, currency, localTax } = financial
  console.log(financial)
  return (
    <ListCardWrapper>
      <TotalCard>
        <List.Item actions={[<span className="heading-06-regular-td">{formatPrice(subtotal / 100, currency)}</span>]}>
          <List.Item.Meta title={subTotalText} />
        </List.Item>
        {localTax && localTax > 0 && (
          <List.Item actions={[<span className="heading-06-regular-td">{formatPrice(localTax / 100, currency)}</span>]}>
            <List.Item.Meta title={localTaxText} />
          </List.Item>
        )}
        <List.Item actions={[<span className="heading-06-regular-td">{formatPrice(tax / 100, currency)}</span>]}>
          <List.Item.Meta title={taxText} />
        </List.Item>
        <List.Item actions={[<span className="paragraph-03-bold">{formatPrice(total / 100, currency)}</span>]}>
          <List.Item.Meta title={totalText} />
        </List.Item>
      </TotalCard>
    </ListCardWrapper>
  )
}

export default TransactionPanelTotal
