import { Button, Modal, Row, Space } from 'antd'
import { filledCircleErrorSVG } from 'assets/svg/error'
import { ConflictingPlanCustomFooterLayout } from 'atom/chargers'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface props {
  visible: boolean
  title: string
  description: string
  onCancel: () => void
  onSubmit: () => void
  cancelText?: string
  submitText?: string
}

export const CancelModal: React.FC<props> = ({
  visible,
  title,
  description,
  onCancel,
  onSubmit,
  cancelText,
  submitText,
}) => {
  const { cancelText: cancel, removeText } = useGeneralTranslation()

  return (
    <Modal visible={visible} onCancel={onCancel} width={450} footer={null} closable={false} centered={true}>
      <Row align="middle" gutter={12}>
        <Space>
          <div>{filledCircleErrorSVG}</div>
          <div style={{ fontSize: '18px', fontWeight: 700 }}>{title}</div>
        </Space>
      </Row>
      <span className="paragraph-01-regular">{description}</span>

      <ConflictingPlanCustomFooterLayout>
        <Button type="ghost" className="half-width-button" onClick={onCancel}>
          {cancelText || cancel}
        </Button>
        <Button
          className="ant-btn-default ant-btn-dangerous half-width-button"
          type="primary"
          onClick={onSubmit}
          style={{ marginLeft: '12px' }}
        >
          {submitText || removeText}
        </Button>
      </ConflictingPlanCustomFooterLayout>
    </Modal>
  )
}
