import { Billing } from './billing'
import { ServiceStatus } from './charger'
import { Installer } from './installer'
import { ListingRef } from './listing'
import { Loitering, UniformLoitering } from './loitering'
import { MasterAccountRef, MasterAccountRefPayload } from './master-account'
import { PartnerRef } from './partner'
import { PriceType } from './price'
import { PropertyMgmtComp } from './property-management'
import { Country } from './stripe'
import { Tagging } from './tagging'
import { DashboardAccessType } from './user'

export const tenantStatusesDefault = [
  'draft',
  'pending_sa',
  'configuration',
  'direct_deposit_information',
  'onboarding_complete',
  'active',
]

export const tenantStatuses = [...tenantStatusesDefault, 'on_hold', 'inactive', 'deleted']

export type StatusType =
  | 'In Progress'
  | 'Commissioned'
  | 'Active'
  | 'In Maintenance'
  | 'Non-Networked'
  | 'Tester – Internal'
  | 'Tester – External'
  | 'Decommissioned'
export type AccessPolicyType = 'Public' | 'Private Individual' | 'Private Shared' | 'Employees Only' | 'Tenants Only'
export type ServiceLevelType = 'Energy Monitoring' | 'Energy Monitoring + Automatic Billing' | 'Non-Networked'
export type NetwordDeviceType = 'SWTCH modem' | 'Cellular' | 'Building Wi-Fi' | 'Wired Ethernet (RJ45)' | 'Other'
export type TenantStatus = typeof tenantStatuses[number]

export type AdditionalServiceFeeType = 'off' | 'on'
export type UniformAccessPolicyType = 'private' | 'public'
export type TaxRegistrationType = 'registered' | 'not_registered' | 'joint_elected'
export type ChargerSetting =
  | 'arena_or_stadium'
  | 'campground'
  | 'fleet'
  | 'gas_station'
  | 'government'
  | 'hospital'
  | 'hotel'
  | 'multi_unit_residential'
  | 'on_street'
  | 'parking_lot'
  | 'parking_garage'
  | 'public'
  | 'retail'
  | 'school_university'
  | 'single_family_residential'
  | 'workplace'

export type TenantListingAuthorsType = {
  userId: string
  name: string
}
export type TenantListingStripeAccountsType = {
  id: number
  personId: string
  name: string
  country?: Country
}

export interface TenantRef {
  id: string
  name: string
  combineName: string
  displayName?: string
  country?: string
  status?: TenantStatus
  chargerCount?: number
  listingTitles?: ListingRef[]
  swtchCare?: boolean
}

export interface NewTenantRef {
  id: string
  name: string
  displayName?: string
  access: DashboardAccessType
  driver: boolean
}

export interface Location {
  latitude?: number
  longitude?: number
  address?: string
  listingTimezone?: string
  postalCode?: string
  city?: string
  province?: string
  country?: string
}

export interface WeeklySchedule {
  startHour: string
  endHour: string
  dayOfWeek: number
}

export interface TenantPartner {
  id: number
  partnerId: number
  partnerName: string
  pmsCode: string | null
}

export interface Tenant
  extends TenantRef,
    TenantOverview,
    TenantAccessCode,
    TenantLoadManagement,
    Billing,
    TenantPlatform {
  connectorCount?: number
  location?: Location
  weeklySchedules?: WeeklySchedule[]
  tenantContacts: TenantContactInfo[]
  tenantUniformPricing: TenantUniformPricing
  tenantDeployment: TenantDeployment
  publishToOcpi: boolean
  eyedroIpAddress: string
  uniformPricing: boolean
  allowUniformLocation: boolean
  allowUniformAccessPolicy: boolean
  displayListingsOnMap: boolean
  uniformAccessPolicy: UniformAccessPolicyType
  billing?: Billing
  taxRegistration: TaxRegistrationType
  taxNumber: string | null
  chargerSetting?: ChargerSetting
  updatedAt?: string
  revSharePartner?: Tagging
  revSharePartnerPercent?: number
}

export interface TenantPlatform {
  tenantPartners?: TenantPartner[]
  tenant_partners?: TenantPartner[]
}

export interface TenantPayload
  extends TenantRef,
    TenantOverviewPayload,
    TenantAccessCode,
    TenantLoadManagement,
    Billing,
    TenantPlatform {
  connectorCount?: number
  chargerCount?: number
  location?: Location
  weeklySchedules?: WeeklySchedule[]
  tenantContacts: TenantContactInfo[]
  tenantUniformPricing: TenantUniformPricing
  tenantDeployment: TenantDeployment
  publishToOcpi: boolean
  eyedroIpAddress: string
  uniformPricing: boolean
  allowUniformLocation: boolean
  allowUniformAccessPolicy: boolean
  displayListingsOnMap: boolean
  uniformAccessPolicy: UniformAccessPolicyType
  billing?: Billing
  taxRegistration: TaxRegistrationType
  taxNumber: string | null
  chargerSetting?: ChargerSetting
  partnerIds?: number[]
  revSharePartner?: Tagging
  revSharePartnerPercent?: number
}

export interface TenantOverview {
  displayName?: string
  masterAccountId?: number
  chargerSetting?: ChargerSetting
  // status?: StatusType // *? This is not used in the backend
  serviceLevel?: ServiceLevelType
  allowUniformServiceStatus: boolean
  uniformServiceStatus?: ServiceStatus
  channelPartner?: PartnerRef
  masterAccount?: MasterAccountRef
  omOptin: boolean
  taxRegistration: TaxRegistrationType
  taxNumber: string | null
  revSharePartnerPercent?: number
  revSharePartner?: Tagging
  tenantContact: TenantContact
  notes?: string
  listingCount: number
  partnerIds?: number[]
}

export interface TenantOverviewPayload {
  displayName?: string
  masterAccountId?: number
  chargerSetting?: ChargerSetting
  serviceLevel?: ServiceLevelType
  allowUniformServiceStatus: boolean
  uniformServiceStatus?: ServiceStatus
  channelPartner?: PartnerRef
  masterAccount?: MasterAccountRefPayload | null
  remittanceEmails?: string[]
  omOptin: boolean
  taxRegistration: TaxRegistrationType
  taxNumber: string | null
  tenantContact?: TenantContact
  notes?: string
  partnerIds?: number[]
}

export interface TenantContact {
  typeOfContact?: string
  namePrimary?: string
  email?: string
  phoneNumber?: string
}

export interface TenantAccount {
  accountName: string
  remittanceEmails: string
  remittanceFrequency: string
}

export interface TenantAccessCode {
  accessCode?: string
  accessPolicy?: AccessPolicyType
  notes?: string
}
export interface TenantContactInfo extends TenantContactAccount, TenantContactOnsite, TenantContactInstaller {
  namePrimary?: string
  title?: string
  phoneNumber?: string
  email?: string
  nameSecondary?: string
  titleSecondary?: string
  phoneNumberSecondary?: string
  emailSecondary?: string
  typeOfContact: string
}

export interface TenantContactAccount {
  propertyManagementCompany?: PropertyMgmtComp
}

export interface TenantContactOnsite {
  evPanelLocation?: string
}

export interface TenantContactInstaller {
  installer?: Installer
}

export interface TenantDeployment {
  electricalService?: string
  networkDevice?: NetwordDeviceType
  notes?: string
  parkingStalls?: string[]
  loadManagementConfiguration?: string[]
  remoteDesktopIds?: string[]
}

export interface TenantUniformPricing extends Loitering, UniformLoitering {
  authorId: string
  stripeAccountId: number
  priceType: PriceType
  pricePerUnitCents: number
  enableTieredPricing: boolean
  preferredPricingPriceType: PriceType
  preferedPricePerUnitCents: number
  enableLoadManagementPricing: boolean
  commisionPercent: number
  flatFee: number
  guestFeeCents: number
  paymentLimitCents: number
  tenantListingStripeAccounts: TenantListingStripeAccountsType[]
  tenantListingAuthors: TenantListingAuthorsType[]
  additionalServiceFee: AdditionalServiceFeeType
  enableTimeOfDayPricing: boolean
  timeOfDayPriceWeekday: number[]
  timeOfDayPriceWeekend: number[]
}

export interface TenantLoadManagement {
  allowLoadManagement: boolean
}

export interface ChargerTroubleShootPermission {
  access_tenant: boolean
  manage_tenant: boolean
  operate_tenant: boolean
  view_tenant: boolean
}

export interface WaitListSetting {
  id: number
  tenant_id: number
  enabled: boolean
  grace_period: number
  reset_time: string
  active_chargers: number[]
  created_at: string
  updated_at: string
}

export interface WaitListSettingResponse {
  message?: string
  data: WaitListSetting
}

export interface WaitListSettingPayload {
  enabled: boolean
  grace_period: number
  reset_time: string
  active_chargers: number[]
}

export interface WaitListQueue {
  person_id: string
  joined_at: string
  grace_period_expires_at: string | null
  state: string
  snooze_allowance: number
  listing_id: string
  listing_title: string
  position: number
  email: string
}

export interface WaitListQueueResponse {
  waitlist_exists: boolean
  queue_id: string
  queue: WaitListQueue[]
}
