import { styled, theme } from '../theme'
import commonSVG from './common/svg'

interface styleProps {
  isCombineRight?: boolean
  isCombineLeft?: boolean
  isCombineRightAndLeft?: boolean
  isCombine?: boolean
  longerWidth?: boolean
  customWidth?: string
  customHeight?: string
  removeSVG?: boolean
  isMobileOrTablet?: boolean
  suffix?: boolean
  suffixMargin?: string
  inputWidth?: string
  minWidth?: number
  placeholderWidth?: string
  maxWidth?: string
}

interface rangePickerProps {
  isCombine?: boolean
}

export const SelectorWrapper = styled.div`
  .ant-select,
  .ant-btn {
    margin-bottom: 8px;
  }
`

export const MultiOptionSelectorContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '160px')};

  .ant-select-selection-overflow {
    margin-left: ${(props) => (props.removeSVG ? '0px' : '18px')};
  }

  .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 15px;
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.mangifyIcon {
    ${commonSVG}
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '11px' : '30px')};
    font-weight: 400;
    font-size: 12px;
  }

  /* for select when mode is NOT multiple/tags */
  /* .ant-select-selection-item { */
  /* left: 24px; */
  /* } */

  .ant-select-selection-item-content {
    max-width: 154px;
  }

  .ant-select-multiple span.ant-select-selection-item {
    max-width: 80px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) =>
      props.isCombineRight
        ? '48px 0px 0px 48px'
        : props.isCombineLeft
        ? '0px 48px 48px 0px'
        : props.isCombine
        ? '0'
        : '48px'};
    border-left-color: ${(props) => (props.isCombine || props.isCombineLeft ? 'transparent' : theme.colors.lightGray)};
    border-right-color: ${(props) =>
      props.isCombine || props.isCombineRight ? 'transparent' : theme.colors.lightGray};
  }
  .ant-select-customize-input .ant-select-selector {
    border-radius: ${(props) => (props.isCombine ? '0' : '48px')};
    border-right-width: 0;
    border-left-width: 0;
  }

  .ant-select-clear {
    right: ${(props) => (props.isCombineLeft ? '50px' : '11px')};
  }
`

export const MultiTenantSelectorContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.longerWidth ? '321px' : '180px')};

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.tenantSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
    height: 13px;
  }

  svg.mangifyIcon {
    ${commonSVG}
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '11px' : '31px')};
    font-weight: 400;
    font-size: 12px;
  }

  .ant-select-selection-item-content {
    max-width: 154px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select > .ant-select-clear {
    z-index: 3;
    width: ${(props) => (props.isMobileOrTablet ? '22px' : '10px')};
    height: ${(props) => (props.isMobileOrTablet ? '32px' : '10px')};
    margin-top: ${(props) => (props.isMobileOrTablet ? '-16px' : '-6px')};
    padding-top: ${(props) => (props.isMobileOrTablet ? '11px' : '0px')};
  }

  .ant-select > .ant-select-clear > .anticon-close-circle > svg {
    width: 12px;
    height: 12px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isCombineRight ? '48px 0 0 48px !important' : '48px')};
    border-radius: ${(props) => (props.isCombineLeft ? '0 48px 48px 0' : '48px')};
    border-radius: ${(props) => (props.isCombineRightAndLeft ? '0' : '48px')};
    border-right-color: ${(props) =>
      props.isCombineRight || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    border-left-color: ${(props) =>
      props.isCombineLeft || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    padding-right: 16px;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${theme.colors.primary} !important;
    box-shadow: none !important;
  }
`

export const MultiOptionSelectorWrapper = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.longerWidth ? '280px' : '140px')};

  .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 15px;
  }

  .ant-select-clear {
    right: ${(props) => (props.removeSVG && !props.isCombineLeft ? '11px' : '52px')};
  }

  .ant-select-selection-overflow {
    margin-left: ${(props) => (props.removeSVG ? '0px' : '18px')};
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.mangifyIcon {
    display: ${(props) => (props.removeSVG ? 'none' : 'block')};
    ${commonSVG}
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '11px' : '28px')};
    font-weight: 400;
    font-size: 12px;
  }

  .ant-select-selection-item-content {
    max-width: 154px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) =>
      props.isCombineLeft
        ? '0 48px 48px 0'
        : props.isCombineRightAndLeft
        ? '0'
        : props.isCombineRight
        ? '48px 0px 0px 48px'
        : '48px'};
    border-right-color: ${(props) =>
      props.isCombineRight || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    border-left-color: ${(props) =>
      props.isCombineLeft || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    /* TODO: add a prop to this for future multiOption selector */
    /* border-right-color: transparent; */
  }
`

export const MultiOptionSelectorRefWrapper = styled.div<styleProps>`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 48px;
  }
`

export const MultiListingSelectorWrapper = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.longerWidth ? '330px' : '188px')};

  .ant-select-clear {
    right: 52px;
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.mangifyIcon {
    ${commonSVG}
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '11px' : '28px')};
    font-weight: 400;
    font-size: 12px;
  }

  .ant-select-selection-item-content {
    max-width: 154px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) =>
      props.isCombineLeft
        ? '0 48px 48px 0'
        : props.isCombineRight
        ? '48px 0 0 48px'
        : props.isCombineRightAndLeft
        ? '0'
        : '48px'};
    border-right-color: ${(props) =>
      props.isCombineRight || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    height: 39px;
  }
`

export const RangePickerContainer = styled.div<rangePickerProps>`
  display: flex;
  .ant-picker {
    border-right-color: ${(props) => (props.isCombine ? 'transparent' : '')};
    border-bottom-right-radius: ${(props) => (props.isCombine ? '0px !important' : '')};
    border-top-right-radius: ${(props) => (props.isCombine ? '0px !important' : '')};
  }

  .ant-picker:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
`

export const SingleSelector = styled.div`
  .ant-input-affix-wrapper {
    min-width: 205px;
    border-radius: 48px;
    line-height: 24px;
    :hover,
    :focus,
    :focus-within,
    :active {
      border-color: ${theme.colors.primary};
      box-shadow: none;
    }
  }

  .ant-input-prefix {
    margin-right: 5px;
  }

  svg.mangifyIcon {
    height: 13px;
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-search {
    .ant-select-selector {
      /* height of the select's body */
      height: 39px;
      span.ant-select-selection-placeholder {
        line-height: 39px;
        position: absolute;
      }
    }
  }

  .ant-select-selection-placeholder {
    /* move the placeholder to the right */
    left: 35px;
    padding-top: 2px;
  }

  .ant-input {
    border-radius: 0px;
  }
`

export const SingleOptionContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  /* for select when mode is NOT multiple/tags */
  svg.mangifyIcon {
    ${commonSVG}
    z-index: 2;
    display: ${(props) => (props.removeSVG ? 'none' : 'block')};
  }
  .ant-select-selection-item {
    left: ${(props) => (props.removeSVG ? '13px' : '24px')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: ${(props) => (props.customHeight ? props.customHeight : '37px')};
    border-radius: ${(props) => (props.isCombineRightAndLeft ? '0' : '48px')};
    border-right-color: ${(props) =>
      props.isCombineRight || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
    border-left-color: ${(props) =>
      props.isCombineLeft || props.isCombineRightAndLeft ? 'transparent' : theme.colors.lightGray};
  }

  .ant-select-auto-complete .ant-select-clear {
    right: 7px;
  }

  .ant-select > .ant-select-selector:hover,
  :focus,
  :focus-within,
  :active {
    border-color: ${theme.colors.neonGreen};
  }

  // move the placeholder to the right
  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    position: absolute;
    left: ${(props) => (props.removeSVG ? '13px' : '30px')};
    line-height: 36px;
    font-size: 12px;
  }

  // move the cursor to the right
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: ${(props) => (props.removeSVG ? '13px' : '29px')};
  }

  .ant-select > .ant-select-selector > .ant-select-selection-search > .ant-select-selection-search-input {
    font-size: 11px;
    text-overflow: ellipsis;
    width: 91%;
    border-radius: 0;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-item {
    line-height: 34px;
  }

  // selected value in the selector stays black
  .ant-select > .ant-select-selector > .ant-select-selection-item > span {
    color: black;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }
`

export const UserSelectorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: 240px;

  .ant-select-selector {
    background: ${theme.colors.cultered};
  }

  .ant-select-single .ant-select-selector {
    border-radius: 50%;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 28px;
    font-weight: 400;
  }

  .ant-select-selection-item-content {
    max-width: 170px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-radius: 50px;
    padding-left: 30px;
  }
`

export const ListingMultiSelectorWrapper = styled.div`
  .ant-select-selection-overflow {
    margin-left: 0;
  }
`

export const ReusableAutoCompleteContainer = styled.div`
  .ant-select-selection-search-input {
    min-height: 39px;
  }
`

export const ReusableSelectorContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.customWidth ? props.customWidth : '170px')};
  height: ${(props) => (props.customHeight ? props.customHeight : '39px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  .ant-select-selection-search {
    max-width: ${(props) => (props.inputWidth ? props.inputWidth : '100%')};
  }
  .ant-select-single input {
    border-radius: 0;
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.tenantSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
    height: 13px;
  }

  svg.mangifyIcon {
    ${commonSVG}
  }

  .ant-select-arrow > button {
    margin-right: -10px;
  }

  .ant-select-clear {
    right: ${(props) => (props.suffix ? (props.suffixMargin ? props.suffixMargin : '50px') : '11px')};
  }

  .ant-select-single .ant-select-selection-placeholder {
    position: absolute;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '11px' : '30px')};
    font-weight: 400;
    font-size: 12px;
  }

  .ant-select-selection-overflow {
    margin-left: ${(props) => (props.removeSVG ? '0px' : '18px')};
    flex-wrap: nowrap;
  }

  .ant-select-single .ant-select-selection-search {
    margin-left: ${(props) => (props.removeSVG ? '0px' : '18px')};
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    margin-left: 5px;
  }

  .ant-select-selection-item-content {
    max-width: ${(props) => props.placeholderWidth ?? '154px'};
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select > .ant-select-clear {
    z-index: 3;
    width: ${(props) => (props.isMobileOrTablet ? '22px' : '10px')};
    height: ${(props) => (props.isMobileOrTablet ? '32px' : '10px')};
    margin-top: ${(props) => (props.isMobileOrTablet ? '-16px' : '-6px')};
    padding-top: ${(props) => (props.isMobileOrTablet ? '11px' : '0px')};
  }

  .ant-select > .ant-select-clear > .anticon-close-circle > svg {
    width: 12px;
    height: 12px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isCombineRight ? '48px 0 0 48px !important' : '48px')};
    border-radius: ${(props) => (props.isCombineLeft ? '0 48px 48px 0 !important' : '48px')};
    border-radius: ${(props) => (props.isCombine ? '0' : '48px')};
    border-right-color: ${(props) =>
      props.isCombineRight || props.isCombine ? 'transparent' : theme.colors.lightGray};
    border-left-color: ${(props) => (props.isCombineLeft || props.isCombine ? 'transparent' : theme.colors.lightGray)};
    padding-right: 16px;
    height: ${(props) => (props.customHeight ? props.customHeight : '39px')};
    flex-wrap: nowrap;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${theme.colors.primary} !important;
    box-shadow: none !important;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-item {
    line-height: 35px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    left: ${(props) => (props.removeSVG ? '2px' : '18px')};
    display: flex;
    align-items: center;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item > span {
    color: black;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: ${theme.colors.black};
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
  }
`

export const MasterAccountSelectorWrapper = styled.div<styleProps>`
  svg.mangifyIcon {
    ${commonSVG}
    left: 15px;
  }

  .ant-select {
    min-width: 100%;
    width: 100% !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isMobileOrTablet || !props.isCombineRightAndLeft ? '48px' : '0px')};
    border-right-color: ${(props) =>
      props.isMobileOrTablet || !props.isCombineRightAndLeft ? '#dedede' : 'transparent'};
    border-left-color: ${(props) =>
      props.isMobileOrTablet || !props.isCombineRightAndLeft ? '#dedede' : 'transparent'};
    width: ${(props) => (props.isMobileOrTablet ? '100%' : '132px')};
  }
`
