import { Divider, Form, FormInstance, Input, Radio, RadioChangeEvent, Space } from 'antd';
import { useListingPosTerminalTranslation } from '../../hooks/translation/useListingPosTerminalTranslation';
import { useState } from 'react';

interface Props {
  form: FormInstance<any>;
}

export const ListingPosTerminal: React.FC<Props> = ({ form }) => {
  const {
    posTerminalText,
    terminalText,
    posNoneTerminalText,
    posNayaxTerminalText,
    posPayterTerminalText,
    nayaxLableText,
    nayaxValidationText,
    payterLableText,
    payterValidationText,
    nayaxPlaceholderText,
    payterPlaceholderText
  } = useListingPosTerminalTranslation();
  const [paymentTerminal, setPaymentTerminal] = useState(form.getFieldValue('paymentTerminal') || 'None');

  const handleChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setPaymentTerminal(value); // Update local state
    form.setFieldsValue({ paymentTerminal: value }); // Update form value
  };

  return (
    <>
      <Divider>{posTerminalText}</Divider>

      <Form.Item label={terminalText} name="paymentTerminal" initialValue= { paymentTerminal }>
        <Radio.Group onChange={handleChange} buttonStyle="solid">
          <Space wrap>
            <Radio.Button value='None' className='radio-button'>
              {posNoneTerminalText}
            </Radio.Button>
            <Radio.Button value='Nayax' className='radio-button'>
              {posNayaxTerminalText}
            </Radio.Button>
            <Radio.Button value='Payter' className='radio-button'>
              {posPayterTerminalText}
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>

      {/* Conditional rendering for serial number fields */}
      {paymentTerminal === 'Nayax' && (
        <Form.Item
          label={ nayaxLableText }
          name="paymentTerminalSerialNumber"
          rules={[{ required: true, message: nayaxValidationText }]}
        >
          <Input placeholder={ nayaxPlaceholderText } />
        </Form.Item>
      )}

      {paymentTerminal === 'Payter' && (
        <Form.Item
          label= { payterLableText }
          name="payterSerialNumber"
          rules={[{ required: true, message: payterValidationText }]}
        >
          <Input placeholder={ payterPlaceholderText } />
        </Form.Item>
      )}
    </>
  );
};
