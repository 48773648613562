import { TenantFilter } from 'models/filter'
import { CodeMessageResponse, EnableLoadMgmtTenantResponse, TenantResponse } from '../models/http'
import { Tenant, TenantPayload, TenantRef } from '../models/tenant'
import { mockInstallers } from './installer-mock'
import { mockPropertyMgmtComps } from './property-management-company-mock'
import { mockTenantsRef } from 'services/data-provider/mock'
import { mockLocations } from './location'
import { mockWeeklySchedules } from './weeklySchedules'
import { mockAvailablePartnerRef } from './tenant-partner-mock'
import { mockRevSharePartner } from './rev-share-partner-mock'
import { mockMasterAccountRef } from './master-account-mock'

export const mockTenant: { [tenantName: string]: TenantRef } = {
  'Multi-Family': {
    ...mockTenantsRef[0],
    status: 'active',
    listingTitles: [
      { id: 1, title: 'LP01' },
      { id: 2, title: 'LP02' },
      { id: 3, title: 'LP03' },
    ],
  },
  'Workplace/Commercial': {
    ...mockTenantsRef[2],
    status: 'inactive',
    listingTitles: [
      { id: 7, title: 'AP01' },
      { id: 8, title: 'AP02' },
      { id: 9, title: 'AP03' },
    ],
  },
  DCFC: {
    ...mockTenantsRef[3],
    status: 'pending_sa',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '158-first-street-irvine-ca': {
    id: '4',
    name: '158-first-street-irvine-ca',
    displayName: '158 First Street Irvine CA',
    combineName: '158 First Street Irvine CA (158-first-street-irvine-ca)',
    status: 'suspended',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '133-Hazelton-Av-Toronto-ON-M5R0A6': {
    id: '5',
    name: '133-Hazelton-Av-Toronto-ON-M5R0A6',
    displayName: 'Hazelton Toronto',
    combineName: 'Hazelton Toronto (133-Hazelton-Av-Toronto-ON-M5R0A6)',
    status: 'deleted',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  swtchhq: {
    id: '6',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
    combineName: 'test_swtch_hq (swtchhq)',
    status: 'active',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '34-Victoria-Av-Kitchener-ON-AZX8E9': {
    id: '7',
    name: '133-Hazelton-Av-Toronto-ON-M5R0A6',
    displayName: 'Hazelton Avenue Kitchener',
    combineName: 'Hazelton Avenue Kitchener (133-Hazelton-Av-Toronto-ON-M5R0A6)',
    status: 'suspended',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '202 - Waverly - Rd - Dartmouth - NS - B2X2H3': {
    id: '8',
    name: '202 - Waverly - Rd - Dartmouth - NS - B2X2H3',
    displayName: '202 Waverly Rd, Dartmouth, NS, B2X2H3',
    combineName: '202 Waverly Rd, Dartmouth, NS, B2X2H3 (202 - Waverly - Rd - Dartmouth - NS - B2X2H3)',
    status: 'draft',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '344-Kitchener-Ave-West-ON-M9E8E4': {
    id: '9',
    name: '344-Kitchener-Ave-West-ON-M9E8E4',
    displayName: '344 Kitchener Ave West ON M9E8E4',
    combineName: '344 Kitchener Ave West ON M9E8E4 (344-Kitchener-Ave-West-ON-M9E8E4)',
    status: 'active',
    swtchCare: true,
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  '614-plum-avenue-los-angeles-ca': {
    id: '10',
    name: '614-plum-avenue-los-angeles-ca',
    displayName: '614 Plum Avenue Los Angeles CA',
    combineName: '614 Plum Avenue Los Angeles CA (614-plum-avenue-los-angeles-ca)',
  },
}

export const updateTenantBasedOnPayload = (tenant: TenantPayload) => {
  const baseTenant = mockTenantById[parseFloat(tenant.id)]
  const overlappingKeys = Object.keys(tenant).filter((key) => key in baseTenant)
  return {
    ...baseTenant,
    ...overlappingKeys.reduce((obj, key) => ({ ...obj, [key]: (tenant as Tenant)[key as keyof Tenant] }), {}),
  }
}

export const mockTenantById: { [tenantId: number]: Tenant } = {
  [Number(mockTenantsRef[0].id)]: {
    ...mockTenantsRef[0],
    chargerCount: 23,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: '12-3456789',
    status: 'active',
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    revSharePartner: mockRevSharePartner.partnerAdminFromListingTable,
    revSharePartnerPercent: 50,
    masterAccount: mockMasterAccountRef.AutoGrid,
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    location: mockLocations[0],
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: mockWeeklySchedules,
    tenantContact: {
      typeOfContact: 'onsite',
      namePrimary: 'Name',
      email: 'email@test.com',
      phoneNumber: '653920394',
    },
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[0],
      },
      {
        namePrimary: 'new firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'onsite',
      },
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'installer',
        installer: mockInstallers[0],
        nameSecondary: 'secondary first name',
        titleSecondary: 'secondary title',
        phoneNumberSecondary: '1231231313',
        emailSecondary: 'sdfsdf23f@gmail.com',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Wired Ethernet (RJ45)',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    tenantPartners: mockAvailablePartnerRef,
    allowLoadManagement: false,
    allowUniformServiceStatus: true,
    uniformServiceStatus: 'under_repair',
    tenantUniformPricing: {
      tenantListingAuthors: [
        {
          userId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'xwei tang',
        },
        {
          userId: '7_SfFp5X0-kKx620vmnk5A',
          name: 'weihang tang',
        },
        {
          userId: 'QlViWeENlQC7uJz61grRSw',
          name: 'Laura Bryson',
        },
        {
          userId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Sam Arabastani',
        },
        {
          userId: 'Li4HGdIsPIsZFVC4L4G6MA',
          name: 'Laura Bryson',
        },
        {
          userId: 'VrIO5E-X7TrGciHUAzrfcQ',
          name: 'Reinier Lamers',
        },
      ],
      tenantListingStripeAccounts: [
        {
          id: 1,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'primer',
        },
        {
          id: 2,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Test123',
        },
        {
          id: 3,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Quadreal Property Group',
        },
        {
          id: 4,
          personId: 'QlViWeENlQC7uJz61grRSw',
          name: 'SWTCH Energy Inc.',
        },
        {
          id: 5,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'Test1234',
        },
        {
          id: 6,
          personId: '7_SfFp5X0-kKx620vmnk5A',
          name: '123',
        },
      ],
      authorId: 'NHAwTS6YcUkFb4SYT_4k2w',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 65,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: true,
      disableLoiteringForTenantMember: true,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
    listingCount: 0,
  },
  [Number(mockTenantsRef[3].id)]: {
    ...mockTenantsRef[3],
    chargerCount: 23,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: '12-3456789',
    status: 'active',
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    revSharePartnerPercent: 20,
    masterAccount: mockMasterAccountRef.SmartOne,
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    location: mockLocations[0],
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: mockWeeklySchedules,
    tenantContact: {
      typeOfContact: 'onsite',
      namePrimary: 'Name',
      email: 'email@test.com',
      phoneNumber: '653920394',
    },
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[0],
      },
      {
        namePrimary: 'new firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'onsite',
      },
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'installer',
        installer: mockInstallers[0],
        nameSecondary: 'secondary first name',
        titleSecondary: 'secondary title',
        phoneNumberSecondary: '1231231313',
        emailSecondary: 'sdfsdf23f@gmail.com',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Wired Ethernet (RJ45)',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    allowLoadManagement: false,
    allowUniformServiceStatus: true,
    uniformServiceStatus: 'under_repair',
    tenantUniformPricing: {
      tenantListingAuthors: [
        {
          userId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'xwei tang',
        },
        {
          userId: '7_SfFp5X0-kKx620vmnk5A',
          name: 'weihang tang',
        },
        {
          userId: 'QlViWeENlQC7uJz61grRSw',
          name: 'Laura Bryson',
        },
        {
          userId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Sam Arabastani',
        },
        {
          userId: 'Li4HGdIsPIsZFVC4L4G6MA',
          name: 'Laura Bryson',
        },
        {
          userId: 'VrIO5E-X7TrGciHUAzrfcQ',
          name: 'Reinier Lamers',
        },
      ],
      tenantListingStripeAccounts: [
        {
          id: 1,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'primer',
        },
        {
          id: 2,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Test123',
        },
        {
          id: 3,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Quadreal Property Group',
        },
        {
          id: 4,
          personId: 'QlViWeENlQC7uJz61grRSw',
          name: 'SWTCH Energy Inc.',
        },
        {
          id: 5,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'Test1234',
        },
        {
          id: 6,
          personId: '7_SfFp5X0-kKx620vmnk5A',
          name: '123',
        },
      ],
      authorId: 'NHAwTS6YcUkFb4SYT_4k2w',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 65,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: true,
      disableLoiteringForTenantMember: true,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
    listingCount: 0,
  },
  4: {
    id: '4',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
    combineName: 'test_swtch_hq (swtchhq)',
    chargerCount: 23,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: '12-3456789',
    status: 'active',
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    masterAccount: {
      id: 1,
      name: 'mc',
      remittanceEmails: ['test@gmail.com'],
      remittanceFrequency: 'monthly',
    },
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    location: {
      address: '109 ossington ave',
      city: 'Toronto',
      country: 'CA',
      latitude: 43.6373,
      listingTimezone: 'America/Toronto',
      longitude: -79.4242,
      postalCode: 'M6J 0G1',
      province: 'ON',
    },
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: mockWeeklySchedules,
    tenantContact: {
      typeOfContact: 'onsite',
      namePrimary: 'Name',
      email: 'email@test.com',
      phoneNumber: '653920394',
    },
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[0],
      },
      {
        namePrimary: 'new firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'onsite',
      },
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'installer',
        installer: mockInstallers[0],
        nameSecondary: 'secondary first name',
        titleSecondary: 'secondary title',
        phoneNumberSecondary: '1231231313',
        emailSecondary: 'sdfsdf23f@gmail.com',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Wired Ethernet (RJ45)',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    tenantPartners: mockAvailablePartnerRef,
    allowLoadManagement: false,
    allowUniformServiceStatus: true,
    uniformServiceStatus: 'under_repair',
    tenantUniformPricing: {
      tenantListingAuthors: [
        { userId: 'NHAwTS6YcUkFb4SYT_4k2w', name: 'xwei tang' },
        { userId: '7_SfFp5X0-kKx620vmnk5A', name: 'weihang tang' },
        { userId: 'QlViWeENlQC7uJz61grRSw', name: 'Laura Bryson' },
        { userId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Sam Arabastani' },
        { userId: 'Li4HGdIsPIsZFVC4L4G6MA', name: 'Laura Bryson' },
        { userId: 'VrIO5E-X7TrGciHUAzrfcQ', name: 'Reinier Lamers' },
      ],
      tenantListingStripeAccounts: [
        { id: 1, personId: 'NHAwTS6YcUkFb4SYT_4k2w', name: 'primer' },
        { id: 2, personId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Test123' },
        { id: 3, personId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Quadreal Property Group' },
        { id: 4, personId: 'QlViWeENlQC7uJz61grRSw', name: 'SWTCH Energy Inc.' },
        { id: 5, personId: 'NHAwTS6YcUkFb4SYT_4k2w', name: 'Test1234' },
        { id: 6, personId: '7_SfFp5X0-kKx620vmnk5A', name: '123' },
      ],
      authorId: 'NHAwTS6YcUkFb4SYT_4k2w',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 65,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: true,
      disableLoiteringForTenantMember: true,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
    listingCount: 0,
  },
  2: {
    id: '2',
    name: 'lan-fake-tenant',
    displayName: 'lan-fake-tenant-display-name',
    combineName: 'lan-fake-tenant-display-name (lan-fake-tenant)',
    chargerCount: 15,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: null,
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    status: 'active',
    location: {
      address: '110 ossington ave',
      city: 'Toronto',
      country: 'CA',
      latitude: 43.6373,
      listingTimezone: 'America/Toronto',
      longitude: -79.4242,
      postalCode: 'M6J 0G1',
      province: 'ON',
    },
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: mockWeeklySchedules,
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[1],
      },
      {
        namePrimary: 'new firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'owner',
      },
      {
        namePrimary: 'new updated firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'owner new',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Cellular',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    tenantPartners: mockAvailablePartnerRef,
    allowLoadManagement: false,
    allowUniformServiceStatus: false,
    uniformServiceStatus: 'service_mode',
    tenantUniformPricing: {
      tenantListingAuthors: [
        { userId: 'NHAwTS6YcUkFb4SYT_4k2w', name: 'xwei tang' },
        { userId: '7_SfFp5X0-kKx620vmnk5A', name: 'weihang tang' },
        { userId: 'QlViWeENlQC7uJz61grRSw', name: 'Laura Bryson' },
        { userId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Sam Arabastani' },
        { userId: 'Li4HGdIsPIsZFVC4L4G6MA', name: 'Laura Bryson' },
      ],
      tenantListingStripeAccounts: [
        { id: 1, personId: 'NHAwTS6YcUkFb4SYT_4k2w', name: 'primer' },
        { id: 2, personId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Test123' },
        { id: 3, personId: 'v7NpvrHi6nT225fFCs9t3A', name: 'Quadreal Property Group' },
        { id: 4, personId: 'QlViWeENlQC7uJz61grRSw', name: 'SWTCH Energy Inc.' },
        { id: 5, personId: '7_SfFp5X0-kKx620vmnk5A', name: 'Test123' },
      ],
      authorId: 'v7NpvrHi6nT225fFCs9t3A',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 34,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: false,
      disableLoiteringForTenantMember: false,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
    tenantContact: {
      typeOfContact: 'onsite',
      namePrimary: 'Name',
      email: 'email@test.com',
      phoneNumber: '653920394',
    },
    listingCount: 2,
  },
}

export const mockEnableLoadMgmtTenantReponse = {
  data: [
    {
      tenants: [{ tenant_id: '4', tenant_name: 'engineering' }],
    },
  ],
  code: 200,
  message: "Tenant's load management has been enabled",
} as EnableLoadMgmtTenantResponse

export const mockDisableLoadMgmtTenantResponse = {
  code: 200,
  message: "Tenant's load management has been disabled",
} as CodeMessageResponse

export const mockGetTenantsLoadMgmtResponse = {
  data: [
    {
      tenants: [
        {
          tenant_id: '25',
          tenant_name: 'Girardin-Bluebird-Dashboard',
        },
      ],
    },
  ],
  code: 200,
  message: 'Tenant data retrieved successfully',
} as EnableLoadMgmtTenantResponse

export const mockTenantContact = [
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'account',
    propertyManagementCompany: mockPropertyMgmtComps[0],
    masterAccount: { id: 1, email: 'cor@gmail.com', name: 'Corporate 1' },
  },
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'onsite',
    masterAccount: { id: 2, email: 'cor@gmail.com', name: 'Corporate 2' },
  },
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'installer',
    installer: mockInstallers[1],
    masterAccount: { id: 3, email: 'cor@gmail.com', name: 'Corporate 3' },
  },
]

export const mockTenants = [
  {
    ...mockTenantsRef[0],
    chargerCount: 12,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    status: 'active',
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    location: mockLocations[0],
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    listingTitles: [
      { id: 5, title: 'L6652' },
      { id: 6, title: 'L6653' },
      { id: 7, title: 'L6654' },
      { id: 8, title: 'L6655' },
      { id: 9, title: 'L6656' },
      { id: 10, title: 'L6657' },
      { id: 11, title: 'L6658' },
      { id: 12, title: 'L6659' },
      { id: 13, title: 'L6660' },
      { id: 14, title: 'L6661' },
      { id: 15, title: 'L6662' },
      { id: 16, title: 'L6663' },
      { id: 17, title: 'L6664' },
    ],

    weeklySchedules: mockWeeklySchedules,
  },
  {
    ...mockTenantsRef[2],
    chargerCount: 30,
    eyedroIpAddress: null,
    status: 'draft',
    listingTitles: [
      { id: 18, title: 'L7715' },
      { id: 19, title: 'L7716' },
      { id: 20, title: 'L7717' },
      { id: 21, title: 'L7718' },
      { id: 22, title: 'L7719' },
      { id: 23, title: 'L7720' },
      { id: 24, title: 'L7721' },
      { id: 25, title: 'L7722' },
      { id: 26, title: 'L7723' },
      { id: 27, title: 'L7724' },
    ],
  },
  {
    id: '3',
    name: '123-lake-street',
    displayName: '',
    chargerCount: 28,
    status: 'suspended',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  {
    id: '6',
    name: '83-beaver-hall',
    displayName: 'beaver hall',
    chargerCount: 73,
    status: 'inactive',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
  {
    id: '5',
    name: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8',
    displayName: 'Green North Road',
    chargerCount: 7,
    status: 'deleted',
    listingTitles: [
      { id: 4, title: 'DISCARDTEST1' },
      { id: 5, title: 'DISCARDTEST2' },
      { id: 6, title: 'DISCARDTEST3' },
    ],
  },
] as Tenant[]

export const mockTenantsResponse = (filter: TenantFilter): TenantResponse => {
  let filterMockTenants = mockTenants

  if (filter.tenants && filter.tenants.length > 0) {
    filterMockTenants = mockTenants.filter((mockTenant) => {
      return (
        filter.tenants &&
        filter.tenants.some((filterTenant) => {
          return filterTenant.id === mockTenant.id
        })
      )
    })
  }

  if (filter.status && filter.status.length > 0) {
    filterMockTenants = mockTenants.filter((mockTenant) => {
      return (
        filter.status &&
        filter.status.some((status) => {
          return status === mockTenant.status
        })
      )
    })
  }

  return {
    data: filterMockTenants,
    pagination: {
      currentPage: filter.page || 1,
      perPage: 20,
      totalEntries: filterMockTenants.length,
    },
  }
}

export const mockTenantsPermission = {
  access_tenant: true,
  manage_tenant: true,
  operate_tenant: true,
  view_tenant: true,
}

export const mockWaitListGetResponse = {
  data: {
    id: 1,
    tenant_id: 4,
    enabled: true,
    grace_period: 10,
    reset_time: '00:00',
    active_chargers: [10, 60],
    created_at: '2025-02-04T21:55:10.261Z',
    updated_at: '2025-02-05T16:11:28.842Z',
  },
}

export const mockWaitListQueueResponse = {
  waitlist_exists: true,
  queue_id: 'cee0187e-cd85-423e-9780-ac2a750bc734',
  queue: [
    {
      person_id: 'iN_hiPjsW4kLZafohAdRog',
      joined_at: '2025-02-07T17:17:34Z',
      grace_period_expires_at: null,
      state: 'waiting',
      snooze_allowance: 1,
      listing_id: '',
      listing_title: '',
      position: 1,
      email: 'demo@swtchenergy.com',
    },
  ],
}

export const mockWaitListSettingResponse = {
  message: 'Waitlist settings created successfully',
  ...mockWaitListGetResponse,
}
