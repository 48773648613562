import { MinusCircleOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  PageHeader,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
} from 'antd'
import { checkmarkCircleSVG } from 'assets/svg/checkmark'
import EVChargerSVG from 'assets/svg/ev_charging'
import { InfoIcon } from 'assets/svg/info'
import { Box } from 'atom/box'
import { InputWrapper, WaitListTabWrapper } from 'atom/chargers'
import { layout } from 'atom/form/page-layout'
import CoreBadge from 'components/badge/Badge'
import { ReusableSelector } from 'components/reusableSelector/selector'
import TenantFooter from 'features/tenants/TenantTabOverview/TenantFooter'
import { useAppHeaderTranslation } from 'hooks/translation/useAppHeaderTranslation'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useNotifications } from 'hooks/useNotification'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { useSelector } from 'hooks/useSelector'
import { useTenantTabOverview } from 'hooks/useTenantTabOverview'
import { ListingsResponse } from 'models/http'
import { ListingRef, NewListing } from 'models/listing'
import { Tenant, TenantPayload, WaitListQueue, WaitListSettingPayload } from 'models/tenant'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FindListings, GetListings } from 'services/data-provider/listing'
import {
  CreateWaitListDetails,
  GetQueueDetails,
  GetWaitListDetails,
  ModifyWaitListDetails,
} from 'services/data-provider/tenants'
import { useAppState } from 'state'
import { theme } from 'theme'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabWaitList: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { loading, form, disabled, setDisabled } = useTenantTabOverview(tenant, onSave)

  const schedules = Array.from(Array(24), (_, index) => index)

  const { isMockUpEnabled } = useAppState()
  const { chargersText } = useAppHeaderTranslation()

  const {
    waitlistDescriptionText,
    chargersNotLinkedText,
    chargersNotLinkedTooltipText,
    allAssignedText,
    enabledText,
    enabledTooltipText,
    gracePeriodText,
    gracePeriodTooltipText,
    resetTimeText,
    resetTimeTooltipText,
    chargersTooltipText,
    activeWaitlistText,
    driverNameText,
    positionText,
    snoozeLimitText,
    snoozeLimitTooltipText,
    joinedWaitlistText,
    saveSuccessText,
    saveErrorText,
  } = useTenantTranslation()

  const { selectedListingsText } = usePricingSchemasTranslation()

  const {
    generalDebounceFetcher,
    multiHandlePlaceholder,
    multiHandleChange,
    renderMultiOptions,
    multiListingTitleAndIdHandleValue,
  } = useSelector()

  const { openSuccessNotification, openErrorNotification } = useNotifications()

  const [allListings, setAllListings] = useState<ListingRef[]>([])
  const [activeWaitListLoading, setActiveWaitListLoading] = useState(false)
  const [initialWaitListDetails, setInitialWaitListDetails] = useState<WaitListSettingPayload>({
    enabled: false,
    grace_period: 0,
    reset_time: '-',
    active_chargers: [],
  })

  const [hasExistingWaitList, setHasExistingWaitList] = useState(false)
  const [isLoadingWaitList, setIsLoadingWaitList] = useState(true)

  const chargersNotAssigned = allListings.filter(({ id }) => !initialWaitListDetails.active_chargers?.includes(id))
  const chargersAssigned = allListings.filter(({ id }) => initialWaitListDetails.active_chargers?.includes(id))

  const { chargerIdsExpanded, tagsRef, isMultiLine, setChargerIdsExpanded } = useResizeObserver(
    tenant,
    chargersNotAssigned,
  )

  const [activeWaitListDataSource, setActiveWaitListDataSource] = useState<WaitListQueue[]>([])

  useEffect(() => {
    GetListings(isMockUpEnabled, { defaultTenant: tenant, allListings: true })
      .then((listings: NewListing[] | ListingsResponse) => {
        let allListings: NewListing[] = []
        if (Array.isArray(listings)) {
          allListings = listings
        }
        setAllListings(allListings)
        getWaitListDetails(allListings)
      })
      .catch((error) => console.log(error))

    getQueueDetails()
  }, [tenant])

  const getWaitListDetails = (allListings: NewListing[]) => {
    GetWaitListDetails(Number(tenant.id), isMockUpEnabled)
      .then(({ data }) => {
        const { enabled, grace_period, reset_time, active_chargers } = data
        setInitialWaitListDetails({ enabled, grace_period, reset_time, active_chargers })
        setHasExistingWaitList(true)
      })
      .catch((error) => {
        setInitialWaitListDetails({ ...initialWaitListDetails, active_chargers: allListings.map((l) => l.id) })
        setHasExistingWaitList(false)
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingWaitList(false)
        }, 1000)
      })
  }

  const getQueueDetails = async () => {
    setActiveWaitListLoading(true)
    GetQueueDetails(Number(tenant.id), isMockUpEnabled)
      .then(({ queue }) => {
        setActiveWaitListDataSource(queue || [])
      })
      .catch((error) => {
        openErrorNotification(error)
      })
      .finally(() => {
        setActiveWaitListLoading(false)
      })
  }

  const activeWaitListColumns = [
    {
      title: driverNameText,
      dataIndex: 'email',
      key: 'name',
      width: '25%',
    },
    {
      title: positionText,
      dataIndex: 'position',
      key: 'position',
      width: '25%',
    },
    {
      title: (
        <Row align="middle" style={{ gap: '10px' }}>
          <span>{snoozeLimitText}</span>
          <Tooltip title={snoozeLimitTooltipText}>
            <InfoIcon />
          </Tooltip>
        </Row>
      ),
      dataIndex: 'snooze_allowance',
      key: 'snooze_allowance',
      width: '25%',
    },
    {
      title: joinedWaitlistText,
      dataIndex: 'joined_at',
      key: 'joined_at',
      width: '25%',
      render: (time: string) => moment(time).format('MM/DD/YYYY HH:mm:ss'),
    },
  ]

  const formatTimeString = (time: number | string): string => {
    if (time === '-') return '-'
    const numTime = Number(time)
    return numTime < 10 ? `0${numTime}:00` : `${numTime}:00`
  }

  const handleSaveWaitList = async () => {
    try {
      const waitListPayload = initialWaitListDetails

      if (hasExistingWaitList) {
        await ModifyWaitListDetails(Number(tenant.id), waitListPayload, isMockUpEnabled)
      } else {
        await CreateWaitListDetails(Number(tenant.id), waitListPayload, isMockUpEnabled)
      }
      openSuccessNotification(saveSuccessText)
      setDisabled(true)
    } catch (error) {
      openErrorNotification(saveErrorText)
    }
  }

  const updateFormValues = (key: keyof WaitListSettingPayload) => (value: any) => {
    setInitialWaitListDetails((prev) => ({ ...prev, [key]: value }))
  }

  return (
    <WaitListTabWrapper>
      <Spin spinning={loading || isLoadingWaitList}>
        <PageHeader title={<div className="heading-06-regular">{waitlistDescriptionText}</div>} />
        <Card bodyStyle={{ boxShadow: '0px 4px 17px 0px #00000012', marginBottom: '16px' }} style={{ border: 0 }}>
          <Row align="middle" style={{ marginBottom: '15px', gap: '10px' }}>
            <span className="heading-06-bold">{chargersNotLinkedText}</span>
            <Tooltip title={chargersNotLinkedTooltipText} className="question-svg">
              <InfoIcon />
            </Tooltip>
          </Row>
          {chargersNotAssigned.length > 0 ? (
            <div
              style={{ display: 'flex', alignItems: chargerIdsExpanded ? 'flex-start' : 'center' }}
              className="chargers-linked"
            >
              <span className="ev-charger-svg">
                <EVChargerSVG color={theme.colors.primary} />
              </span>
              <span
                style={{
                  fontSize: '32px',
                  fontWeight: 900,
                  marginLeft: '8px',
                  marginRight: '24px',
                  marginTop: chargerIdsExpanded ? '-7px' : '0px',
                }}
              >
                {chargersNotAssigned.length}
              </span>
              <Space wrap>
                <div ref={tagsRef} className={`tags ${chargerIdsExpanded ? 'expanded' : ''}`}>
                  {chargersNotAssigned.map((charger, index) => (
                    <CoreBadge title={charger.title} />
                  ))}
                </div>
              </Space>
              {isMultiLine && (
                <div style={{ padding: '5px' }}>
                  {chargerIdsExpanded ? (
                    <MinusCircleOutlined onClick={() => setChargerIdsExpanded(!chargerIdsExpanded)} />
                  ) : (
                    <PlusCircleOutlined onClick={() => setChargerIdsExpanded(!chargerIdsExpanded)} />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <span className="ev-charger-svg">{checkmarkCircleSVG}</span>
              <span
                style={{
                  fontSize: '32px',
                  fontWeight: 900,
                  marginLeft: '8px',
                  marginRight: '24px',
                }}
              >
                {allAssignedText}
              </span>
            </div>
          )}
        </Card>
        {!isLoadingWaitList && (
          <Box padding>
            <Form {...layout} form={form} name={`tenant-${tenant?.id}`} onFinish={handleSaveWaitList}>
              <InputWrapper>
                <Form.Item name="enabled" label={enabledText}>
                  <Row align="middle" style={{ gap: '10px' }}>
                    <Switch
                      disabled={disabled}
                      checked={initialWaitListDetails.enabled}
                      onChange={updateFormValues('enabled')}
                    />
                    <Tooltip title={enabledTooltipText}>
                      <InfoIcon />
                    </Tooltip>
                  </Row>
                </Form.Item>
                <Form.Item className="align-center" name="grace_period" label={gracePeriodText}>
                  <Row align="middle" style={{ gap: '10px' }}>
                    <InputNumber
                      style={{ width: '30%' }}
                      disabled={disabled || !initialWaitListDetails.enabled}
                      value={initialWaitListDetails.grace_period}
                      defaultValue={0}
                      onChange={updateFormValues('grace_period')}
                      placeholder="10"
                      precision={0}
                      min={0}
                    />
                    <Tooltip title={gracePeriodTooltipText}>
                      <InfoIcon />
                    </Tooltip>
                  </Row>
                </Form.Item>

                <Form.Item className="align-center" label={resetTimeText} name="reset_time">
                  <Row align="middle" style={{ gap: '10px' }}>
                    <Select
                      style={{ width: '30%' }}
                      disabled={disabled || !initialWaitListDetails.enabled}
                      dropdownClassName="dropdown-large-font"
                      placeholder="Select Time"
                      value={initialWaitListDetails.reset_time}
                      onChange={updateFormValues('reset_time')}
                    >
                      <Select.Option value="-" key="-">
                        -
                      </Select.Option>
                      {schedules.map((time) => {
                        const timeString = formatTimeString(time)
                        return (
                          <Select.Option value={timeString} key={timeString}>
                            {timeString}
                          </Select.Option>
                        )
                      })}
                    </Select>
                    <Tooltip title={resetTimeTooltipText}>
                      <InfoIcon />
                    </Tooltip>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="active_chargers"
                  label="Chargers"
                  help={
                    chargersAssigned.length
                      ? `${selectedListingsText}${chargersAssigned.map(({ title }) => title).join(', ') || ''}`
                      : undefined
                  }
                >
                  <Row align="middle" style={{ gap: '10px' }}>
                    <ReusableSelector
                      showSearch={true}
                      onClear={() => updateFormValues('active_chargers')([])}
                      isSingle={false}
                      showMagnifySVG={false}
                      maxTagCount={0}
                      maxWidth="30%"
                      customWidth="30%"
                      isDebounceFetcher={true}
                      handlePlaceholder={multiHandlePlaceholder('title', chargersText)}
                      debounceFetcher={generalDebounceFetcher(
                        (value) => FindListings(isMockUpEnabled, value, tenant),
                        true,
                        undefined,
                        2,
                      )}
                      oneTimeUpdateDefaultValues
                      disabled={disabled || !initialWaitListDetails.enabled}
                      isMockUpEnabled={isMockUpEnabled}
                      placeholder={chargersText}
                      defaultTenants={[tenant]}
                      dropDownList={allListings}
                      selectAllOptions={true}
                      defaultValues={allListings.filter((l) => initialWaitListDetails.active_chargers?.includes(l.id))}
                      defaultValueOptions
                      onOptionsChange={(listing: ListingRef[]) =>
                        updateFormValues('active_chargers')(listing.map((l) => l.id))
                      }
                      handleOptionChange={multiHandleChange('id')}
                      renderOption={renderMultiOptions({
                        val: 'id',
                        label: 'title',
                      })}
                      handleValue={multiListingTitleAndIdHandleValue}
                    />
                    <Tooltip title={chargersTooltipText}>
                      <InfoIcon />
                    </Tooltip>
                  </Row>
                </Form.Item>
                <Divider />
                <TenantFooter form={form} disabled={disabled} setDisabled={setDisabled} />
                <Divider />
                <Col>
                  <Row align="middle" style={{ marginBottom: '16px' }}>
                    <span className={'heading-06-bold'}>{activeWaitlistText}</span>
                    <Button
                      type="text"
                      icon={<ReloadOutlined style={{ fontSize: 14, color: theme.colors.primary }} />}
                      onClick={getQueueDetails}
                    ></Button>
                  </Row>
                  <Table
                    loading={activeWaitListLoading}
                    dataSource={activeWaitListDataSource}
                    columns={activeWaitListColumns}
                    className="modern-table"
                    pagination={false}
                    rowKey="position"
                  />
                </Col>
              </InputWrapper>
            </Form>
          </Box>
        )}
      </Spin>
    </WaitListTabWrapper>
  )
}
