import { Button, Form, Modal } from 'antd'
import { useNotifications } from 'hooks/useNotification'
import React, { useEffect, useState } from 'react'
import { sendSVG } from '../../assets/svg/send'
import { StyledDrawer } from '../../atom/drawer'
import { HorizontalLine } from '../../atom/horizontal-line'
import {
  ButtonTextSpacing,
  FooterLayout,
  Heading,
  HeadingDescription,
  MobileLineContainer,
} from '../../atom/user-invite'
import { AlertError } from '../../components/error'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../models/error'
import { User, UserInviteSuccess, UserPermissions } from '../../models/user'
import { NewInviteUserEmails } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import { MemoizedEditForm } from './modal/user-edit-form'
import UserEmail from './modal/user-email'
import UserInviteRole from './modal/user-invite-role'
import { AccessPlanObj } from './user-edit-redesign'

interface UserInvitationProps {
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserInvited: (user: User | UserInviteSuccess, isMockUpEnabled: boolean) => void
}

export const UserInvitationRedesign: React.FC<UserInvitationProps> = ({ onCancel, onUserInvited }) => {
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchError>()

  const [selectedEmails, setSelectedEmails] = useState<string[]>([])

  const [loading, setLoading] = useState(false)
  const [disableSendInviteButton, setDisableSendInviteButton] = useState(true)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([
    { name: '', id: '', access: '', driver: false, portal: true, driverOption: true },
  ])
  const [isManager, setIsManager] = useState(false)

  const [allAccessPlans, setAllAccessPlans] = useState<AccessPlanObj>({})

  const isAdmin = currentUser?.role === 'admin'

  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const { heading, headingInfo, sendInvite, cancelInviteText } = useUserTranslation()

  const [selectedRole, setSelectedRole] = useState<'driver' | 'swtch_os_user'>('driver')

  useEffect(() => {
    let manager = false
    currentUser?.accesses.map((access) => {
      access.permissions.map((permission) => {
        if (permission === 'tenant.manager' || permission === 'tenant.charger_operator') manager = true
        return manager
      })
      return manager
    })
    setIsManager(manager)
  }, [])

  const disableSendButton = (selectedRole: 'driver' | 'swtch_os_user') => {
    if (selectedRole === 'driver') {
      return (
        userPermissions.length === 0 ||
        userPermissions.some((permission) => {
          //No tenant (site)
          if (!permission.name || !permission.id) {
            return true
            //No accessPlan or no discount plan
          } else if (!permission.accessPlan?.length && !permission.discountPlan?.length) {
            return true
          } else {
            return false
          }
        })
      )
    } else {
      return (
        userPermissions.length === 0 ||
        userPermissions.some((permission) => !permission.name || !permission.id || !permission.access)
      )
    }
  }

  useEffect(() => {
    const disable = selectedEmails.length === 0 || disableSendButton(selectedRole)
    setDisableSendInviteButton(disable)
  }, [selectedEmails, selectedRole, userPermissions])

  useEffect(() => {
    form.validateFields(['email'])
  }, [form.getFieldValue('email')])

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    if (userPermissions.some((permission) => permission.name === '')) return
    const isDriver = selectedRole === 'driver'
    const selectedTenants = userPermissions.map(({ name, id, access, driver }) => ({
      name,
      id,
      access: isDriver ? 'none' : access,
    }))
    const selectedAccessPlanIds = isDriver
      ? userPermissions.flatMap(({ accessPlan }) => accessPlan || []).map(({ id }) => id)
      : []
    const users = selectedEmails.map((email) => ({ email }))

    const selectedLocalDiscounts = isDriver
      ? userPermissions.flatMap(({ discountPlan }) => discountPlan || []).map(({ id }) => id)
      : []

    NewInviteUserEmails(selectedTenants, users, selectedAccessPlanIds, selectedLocalDiscounts, isMockUpEnabled)
      .then((invitedUser) => {
        openSuccessNotification(
          `${
            selectedEmails.length > 1 ? `${selectedEmails.length} emails have` : `${selectedEmails[0]} has`
          } been invited`,
        )
        return onUserInvited(invitedUser, isMockUpEnabled)
      })
      .catch((error) => {
        openErrorNotification(
          `Invitation Error: Could not invite ${
            selectedEmails.length > 1 ? `${selectedEmails.length} emails` : selectedEmails[0]
          }`,
        )
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={
            <>
              <Heading>{heading}</Heading>
              <HeadingDescription>{headingInfo}</HeadingDescription>
            </>
          }
          closable={true}
          visible={true}
          afterClose={afterClose}
          onCancel={onCancel}
          onOk={onOk}
          width={543}
          footer={
            <FooterLayout disabled={disableSendInviteButton}>
              <Button style={{ width: '240px' }} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelInviteText}</span>
              </Button>
              <Button style={{ width: '240px' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                <ButtonTextSpacing>
                  <span className="paragraph-02-regular">{sendInvite}</span>
                </ButtonTextSpacing>
                {sendSVG}
              </Button>
            </FooterLayout>
          }
        >
          <Form form={form} layout="vertical" initialValues={{ role: 'driver' }}>
            <UserEmail selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails} />
            <UserInviteRole selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
            <MemoizedEditForm
              userPermissions={userPermissions}
              loading={loading}
              allAccessPlans={allAccessPlans}
              setUserPermissions={setUserPermissions}
              setAllAccessPlans={setAllAccessPlans}
              isNormalUser={!isAdmin && !isManager}
              role={selectedRole}
              isInvite
            />
          </Form>
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            <FooterLayout disabled={disableSendInviteButton}>
              <Button style={{ width: '100%' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                <ButtonTextSpacing>
                  <span className="paragraph-02-regular">{sendInvite}</span>
                </ButtonTextSpacing>
                {sendSVG}
              </Button>
            </FooterLayout>
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{heading}</Heading>
          <HeadingDescription>{headingInfo}</HeadingDescription>
          <HorizontalLine />
          <Form form={form} labelAlign="right" layout="vertical">
            <UserEmail selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails} />
            <UserInviteRole selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
            <HorizontalLine />
            <MemoizedEditForm
              userPermissions={userPermissions}
              loading={loading}
              allAccessPlans={allAccessPlans}
              setUserPermissions={setUserPermissions}
              setAllAccessPlans={setAllAccessPlans}
              role={selectedRole}
              isInvite
            />
          </Form>
        </StyledDrawer>
      )}
    </>
  )
}
