import { styled, theme } from '../theme'

interface props {
  centerTabs?: boolean
}

export const ModifyButtonWrapper = styled.div`
  button {
    height: 38px;
    width: 38px;
    margin-left: 8px;
  }

  svg {
    margin-bottom: 0px;
    margin-top: 7px;
    height: 20px;
  }

  svg path {
    fill: ${theme.colors.black};
    stroke: ${theme.colors.black};
  }
`

export const TabsWrapper = styled.div<props>`
  font-family: ${theme.fontFamily.regularFontFamily};

  // keep old tabs design but update color
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.lightGray};
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: ${(props) => (props.centerTabs ? 'center' : 'flex-start')};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.primary};
    background-color: transparent;
  }

  .ant-tabs-tab {
    padding: 0;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
  }

  .ant-tabs-tab-btn {
    background-color: transparent;
    font-weight: 700;
    text-shadow: none;
    font-family: ${theme.fontFamily.boldFontFamily};
    font-size: 14px;
    padding: 8px 4px;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn:hover {
    background-color: transparent;
    color: ${theme.colors.primary};
  }

  .ant-tabs-tab-active {
    border-bottom: 2px ${theme.colors.primary} solid;
    margin-bottom: -1px;
  }
`

export const InputWrapper2 = styled.div`
  .ant-form-item-control-input-content > .ant-input {
    border-radius: 48px;
  }
`

export const InputWrapper = styled.div`
  font-family: ${theme.fontFamily.regularFontFamily};

  .ant-form-item-control-input-content > .ant-input {
    border-radius: 48px;
    height: 38px;
  }

  .ant-picker {
    height: 39px;
    border-radius: 48px;
  }

  .ant-input-affix-wrapper {
    border-radius: 48px;
    height: 38px;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  textarea {
    border-radius: 9px !important;
  }

  button {
    letter-spacing: 0.05em;
    font-family: ${theme.fontFamily.regularFontFamily};
  }

  // select component CSS
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* height: 38px; */
  }

  /* .ant-select-selection-placeholder { */
  /* .listing-author-selector {
    span.ant-select-selection-placeholder {
      margin-top: 2px;
    }
    .ant-select-selection-search-input {
      cursor: pointer;
      margin-top: 10px;
    }
  } */

  /* .ant-select-selection-placeholder {
    margin-top: 3px;
  } */

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  // for select component with mode="multiple"
  .ant-select-selection-overflow {
    margin-left: 0;
  }

  // end of select component CSS

  // Input and input number CSS
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${theme.colors.primary};
  }

  // For selector CSS
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${theme.colors.disabledInput};
  }

  .ant-switch-checked {
    background-color: ${theme.colors.primary};
  }

  .align-center-label {
    label {
      margin-top: 4px;
    }
  }

  .radio-button {
    height: 38px;
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    align-items: center;
  }
`

export const MultiSelectWrapper = styled.div`
  .ant-select-selection-overflow {
    margin-left: 0;
  }
`

export const InputNumberWrapper = styled.div`
  .ant-input-number {
    border-radius: 0 !important;
  }
`

export const CreateAccessPlanModalWrapper = styled.div`
  .modal-body {
    max-height: 460px;
    overflow-y: auto;
    padding: 24px;
  }

  .ant-form-item {
    margin-bottom: 32px;
    margin-top: 16px;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }

  .ant-col {
    min-height: 0px;
  }

  .time-container {
    .ant-row.ant-form-item .ant-col {
      margin-left: 0;
    }

    .ant-select-selector {
      min-height: 37px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  button {
    color: ${theme.colors.primary};

    &:hover {
      color: ${theme.colors.primary};
    }

    &:focus {
      color: ${theme.colors.primary};
    }
  }

  .ant-btn-text {
    svg {
      path {
        stroke: ${theme.colors.primary};
      }
    }
  }

  .ant-input {
    height: 38px;
    border-radius: 41px;
  }

  .ant-select-selector {
    height: 38px;
  }

  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${theme.colors.disabledInput};
  }

  hr {
    border-top: 1px solid ${theme.colors.greyDivider};
    border-left: 1px solid ${theme.colors.greyDivider};
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .ant-checkbox + span {
    padding-left: 12px;
  }

  .add-button > .ant-row {
    margin-bottom: 0px;
  }

  .footer {
    border-top-color: ${theme.colors.lightGray};
    margin: 0px;
  }

  .circle-radio {
    align-items: baseline;
  }

  .time-section {
    display: flex;
    align-items: flex-start;
    width: 96%;
  }

  .time-switch {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .day-text {
    margin-left: 16px;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-table-column-sorters {
    width: 14% !important;
  }
`

export const EmailDomainSelectorWrapper = styled.div`
  .ant-select-selector {
    height: auto;
    padding: 8px 12px;
  }
  .ant-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-select-selection-placeholder {
    padding: 0px 6px;
  }
  .ant-select-selection-overflow {
    margin-left: 0px;
  }
`

export const AccessPlanFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px 8px;
`

export const WaitListTabWrapper = styled.div`
  .question-svg {
    svg {
      height: 20px;
      width: 20px;
      fill: ${theme.colors.blackOpacity};
      path {
        fill: ${theme.colors.blackOpacity};
      }
    }
  }

  .ev-charger-svg {
    margin-top: 5px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; // This ensures a smooth transition
    align-items: center;
    padding: 5px;
    gap: 5px;
    max-height: 30px; // Initial max height for collapsed state
  }

  .expand-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    outline: none;
  }
`

export const AccessPlanTabWrapper = styled.div`
  .ant-table {
    padding-bottom: 75px;
    min-height: 52vh;
  }

  .question-svg {
    svg {
      height: 20px;
      width: 20px;
      fill: ${theme.colors.blackOpacity};
      path {
        fill: ${theme.colors.blackOpacity};
      }
    }
  }

  .ev-charger-svg {
    margin-top: 5px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .ant-btn-link > svg > path {
    stroke: #7d838a;
  }

  .ant-scroll-number-only-unit {
    font-size: 12px;
    font-weight: 400;
  }

  /* Higher specificity for targeting cells within Ant Design tables */
  .ant-table-tbody > .ant-table-row > .ant-table-cell.multi-line,
  .ant-table-tbody > .ant-table-row > .ant-table-cell > .multi-line {
    vertical-align: top;
  }

  .ant-table-tbody > .ant-table-row > .ant-table-cell.single-line,
  .ant-table-tbody > .ant-table-row > .ant-table-cell > .single-line {
    vertical-align: middle;
  }

  .chargers-linked > .ant-space > .ant-space-item > .non-clickable > .ant-badge > .ant-scroll-number {
    font-size: 12px;
    font-weight: 400;
  }

  .ant-btn-link {
    height: 30px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; // This ensures a smooth transition
    align-items: center;
    padding: 5px;
    gap: 5px;
    max-height: 30px; // Initial max height for collapsed state
  }

  .expand-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    outline: none;
  }
`

export const ErrorTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -22px;

  .danger-text {
    color: ${theme.colors.dangerPrimary};
    margin-bottom: 10px;
  }

  svg {
    margin-right: 5px;
    width: 19px;
    height: 19px;

    path {
      stroke: ${theme.colors.dangerPrimary};
    }
  }
`

export const ConflictingPlanCustomFooterLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 6px;

  .half-width-button {
    width: 50%;
  }

  .ant-btn-dangerous.half-width-button {
    color: ${theme.colors.white} !important;
  }
`
