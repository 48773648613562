import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useTenantTranslation = () => {
  const tenantText = useFormatMessage('dashboard.text.tenant', 'Site')
  const incidencesText = useFormatMessage('dashboard.text.incidences', 'Incidents')
  const uniformServiceStatusWarningText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.chargerServiceStatus.help',
    "IMPORTANT: Selecting one of the values will update to all chargers' service status associated with this site",
  )
  const connectorServiceStatusText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.connectorServiceStatus',
    'Connector Service Status',
  )
  const uniformServiceStatusText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.uniformServiceStatus',
    'Uniform Service Statuses',
  )
  const omOptInText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.heading', 'O&M Opt-in')

  const masterAccountText = renderFormatMessage('dashboard.text.masterAccount', 'Master Account')
  const addNewMasterAccountText = renderFormatMessage(
    'dashboard.placeholder.addNewMasterAccount',
    'Add new master account',
  )
  const accountText = renderFormatMessage('dashboard.text.account', 'Account')

  const channelPartnerText = renderFormatMessage('dashboard.tenantPage.overviewTab.channelPartner', 'Channel Partner')

  const ocppNameText = useFormatMessage('dashboard.tenantPage.detailTab.ocppName.heading', 'OCPP Name')

  const taxRegistrationText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.heading',
    'Tax Registration',
  )

  const taxRegistrationOptionText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.registered',
    'Registered',
  )
  const taxRegistrationOptionNotRegisteredText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered',
    'Not Registered',
  )
  const taxRegistrationOptionJointElectedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected',
    'Joint Elected',
  )
  const remittanceEmailText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.remittanceEmail',
    'Remittance Email(s)',
  )
  const remittanceFrequencyText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.remittanceFrequency',
    'Remittance Frequency',
  )
  const createNewTenantBtn = useFormatMessage('dashboard.tenantsPage.createNewTenantBtn', 'Create New Site')
  const tenantsTableNameHeading = useFormatMessage('dashboard.tenantsPage.table.heading.name', 'Name')
  const tenantsTableChargerCountHeading = useFormatMessage(
    'dashboard.tenantsPage.table.heading.chargerCount',
    'Charger Count',
  )
  const overviewText = renderFormatMessage('dashboard.tenantPage.overviewTab.heading', 'Overview')
  const tenantTabChargerText = useFormatMessage('dashboard.tenantPage.chargersTab.heading', 'Chargers')
  const locationText = renderFormatMessage('dashboard.tenantPage.location.heading', 'Location')
  const accessText = renderFormatMessage('dashboard.text.access', 'Access')
  const pricingText = renderFormatMessage('dashboard.tenantPage.heading.pricing', 'Pricing')
  const pricingSchemaText = renderFormatMessage('dashboard.tenantPage.heading.pricingSchema', 'Pricing Schema')
  const deploymentText = renderFormatMessage('dashboard.tenantPage.heading.deployment', 'Deployment')
  const editAccountInfoText = useFormatMessage('dashboard.tenantPage.overviewTab.editAccountInfo', 'Edit Account Info')
  const commaDelimitedText = useFormatMessage('dashboard.tenantPage.overviewTab.commaDelimited', 'Comma delimited')
  const monthlyText = useFormatMessage('dashboard.tenantPage.overviewTab.monthly', 'Monthly')
  const quarterlyText = useFormatMessage('dashboard.tenantPage.overviewTab.quarterly', 'Quarterly')
  const annuallyText = useFormatMessage('dashboard.tenantPage.overviewTab.annually', 'Annually')
  const swtchCareText = useFormatMessage('dashboard.tenantPage.overviewTab.swtchCare', 'SWTCH Care')
  const editAccountDetailsText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.editAccountDetails',
    'Edit Account Details',
  )
  const caasText = useFormatMessage('dashboard.tenantPage.overviewTab.caas', 'CaaS')
  const tenantStatusText = useFormatMessage('dashboard.tenantPage.tenantTabOverview.tenantStatus', 'Site Status')
  const editStatusText = useFormatMessage('dashboard.tenantPage.tenantTabOverview.editStatus', 'Edit Status')
  const arenaText = 'Arena or Stadium'
  const campgroundText = 'Campground'
  const fleetText = 'Fleet'
  const gasStationText = 'Gas Station'
  const governmentText = 'Government'
  const hospitalText = 'Hospital'
  const hotelText = 'Hotel'
  const multiUniResidentialText = 'Multi Unit Residential'
  const onStreetText = 'On Street'
  const parkingLotText = 'Parking Lot'
  const parkingGarageText = 'Parking Garage'
  const publicText = 'Public'
  const retailText = 'Retail'
  const schoolText = 'School/University'
  const singleFamilyResidentialText = 'Single Family Residential'
  const workplaceText = 'Workplace'
  const cannotAssignTenantAsDeletedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.status.help',
    'NOTE: Tenant cannot be deleted if one or more listings are still attached!',
  )

  const inActiveDescriptionText = useFormatMessage(
    'dashboard.tenantPage.editTenant.inActiveDescription',
    'Veuillez noter que le changement du statut du locataire à Inactif entraînera également la mise à jour des éléments suivants :',
  )

  const inActiveOption1LeftText = useFormatMessage(
    'dashboard.tenantPage.editTenant.inActiveOption1Left',
    'Statuts des services de listings',
  )

  const inActiveOption1RightText = useFormatMessage('dashboard.tenantPage.editTenant.inActiveOption1Right', 'Désactivé')

  const inActiveOption2LeftText = useFormatMessage(
    'dashboard.tenantPage.editTenant.inActiveOption2Left',
    'Accès aux listings',
  )

  const inActiveOption2RightText = useFormatMessage('dashboard.tenantPage.editTenant.inActiveOption2Right', 'Restreint')

  const inActiveOption3LeftText = useFormatMessage(
    'dashboard.tenantPage.editTenant.inActiveOption3Left',
    'Listings masqués sur la carte',
  )

  const inActiveOption3RightText = useFormatMessage('dashboard.tenantPage.editTenant.inActiveOption3Right', 'Vrai')

  const inActiveOption4LeftText = useFormatMessage(
    'dashboard.tenantPage.editTenant.inActiveOption4Left',
    'Partage OCPI',
  )

  const inActiveOption4RightText = useFormatMessage('dashboard.tenantPage.editTenant.inActiveOption4Right', 'Faux')

  const deletedDescriptionText = useFormatMessage(
    'dashboard.tenantPage.editTenant.deletedDescription',
    'Veuillez noter que le changement du statut du locataire à Supprimé entraînera également la mise à jour des éléments suivants :',
  )

  const deletedDescriptionOption1LeftText = useFormatMessage(
    'dashboard.tenantPage.editTenant.deletedDescriptionOption1Left',
    'Partage OCPI',
  )

  const deletedDescriptionOption1RightText = useFormatMessage(
    'dashboard.tenantPage.editTenant.deletedDescriptionOption1Right',
    'Faux',
  )

  const partnerIntegrationText = useFormatMessage(
    'dashboard.tenantPage.partnerIntegrationTab.heading',
    'Platform Integration',
  )
  const partnerText = useFormatMessage('dashboard.tenantPage.partnerIntegrationTab.partner', 'Platform')
  const pricingPlanText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.pricingPlan', 'Pricing Plan')

  const editTenantStatusText = useFormatMessage('dashboard.tenantPage.overviewTab.editTenant', 'Edit Site Status')
  const taggingText = useFormatMessage('dashboard.tagging', 'Tagging')
  const viewSiteDetailsText = useFormatMessage('dashboard.text.viewSiteDetails', 'View Site details')
  const chargerSettingsText = useFormatMessage('dashboard.tenantPage.overviewTab.chargerSettings', 'Charger Settings')
  const chargerSettingsSelectedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.chargerSettingsSelected',
    'Charger Settings Selected',
  )

  const accessPlanText = useFormatMessage('dashboard.text.accessPlan', 'Access Plan')

  const chargersTabUtilizationRateText = useFormatMessage(
    'dashboard.tenantPage.chargersTab.utilizationRate',
    'Utilization Rate',
  )
  const chargersTabUtilizationRateDescriptionText = useFormatMessage(
    'dashboard.tenantPage.chargersTab.utilizationRate.description',
    'Utilization Rate is calculated based on the time the charger is connected to a vehicle, regardless of whether the vehicle is actively charging or not (i.e. Charger OCPP Status Preparing, Charging, Finishing, SuspendedEV).',
  )
  const chargersTabUptimePercentageText = useFormatMessage(
    'dashboard.tenantPage.chargersTab.uptimePercentage',
    'Uptime Percentage',
  )
  const chargersTabUptimePercentageDescriptionText = useFormatMessage(
    'dashboard.tenantPage.chargersTab.uptimePercentage.description',
    "Uptime Percentage measures the time the charger is operational—either online, available for use, or actively charging a vehicle. This percentage excludes downtime caused by factors beyond SWTCH's control, such as vandalism, city-wide outages, or natural disasters.",
  )

  // Add new waitlist translations
  const waitlistDescriptionText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.description',
    "A waitlist can be created to allow drivers to queue for a charger when all chargers have been occupied. Drivers will be notified when it's their turn at the charger.",
  )

  const chargersNotLinkedText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.chargersNotLinked',
    'Chargers Not Linked to Waitlist',
  )

  const chargersNotLinkedTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.chargersNotLinkedTooltip',
    'Chargers that are not part of the waitlist can ONLY be used on a first-come, first-serve basis.',
  )

  const allAssignedText = useFormatMessage('dashboard.tenantPage.waitlistTab.allAssigned', 'All Assigned')

  const enabledText = useFormatMessage('dashboard.tenantPage.waitlistTab.enabled', 'Enabled')

  const enabledTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.enabledTooltip',
    'Turn the waitlist feature on or off for this site. When enabled, if chargers are occupied, the driver will be prompted to join a queue. When disabled, chargers are accessible on a first-come, first-serve basis.',
  )

  const gracePeriodText = useFormatMessage('dashboard.tenantPage.waitlistTab.gracePeriod', 'Grace Period (Minutes)')

  const gracePeriodTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.gracePeriodTooltip',
    'Set the time a driver has to start charging once notified. If they do not plug in within this period, they will be moved to the end of the queue.',
  )

  const resetTimeText = useFormatMessage('dashboard.tenantPage.waitlistTab.resetTime', 'Reset Time')

  const resetTimeTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.resetTimeTooltip',
    'Choose a daily queue reset time to automatically clear the waitlist.',
  )

  const chargersTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.chargersTooltip',
    'Choose which chargers will be part of this waitlist. Drivers will only queue for the selected chargers when they are occupied.',
  )

  const activeWaitlistText = useFormatMessage('dashboard.tenantPage.waitlistTab.activeWaitlist', 'Active Waitlist')

  const driverNameText = useFormatMessage('dashboard.tenantPage.waitlistTab.driverName', 'Driver Name')

  const positionText = useFormatMessage('dashboard.tenantPage.waitlistTab.position', 'Position')

  const snoozeLimitText = useFormatMessage('dashboard.tenantPage.waitlistTab.snoozeLimit', 'Snooze Limit')

  const snoozeLimitTooltipText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.snoozeLimitTooltip',
    'A driver can snooze a maximum of once before they are moved to the end of the queue.',
  )

  const joinedWaitlistText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.joinedWaitlist',
    'Joined Waitlist At (Date/Time)',
  )

  const saveSuccessText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.saveSuccess',
    'Waitlist settings saved successfully',
  )

  const saveErrorText = useFormatMessage(
    'dashboard.tenantPage.waitlistTab.saveError',
    'Failed to save waitlist settings',
  )

  return {
    tenantText,
    incidencesText,
    uniformServiceStatusWarningText,
    connectorServiceStatusText,
    uniformServiceStatusText,
    omOptInText,
    masterAccountText,
    addNewMasterAccountText,
    accountText,
    cannotAssignTenantAsDeletedText,
    channelPartnerText,
    ocppNameText,
    taxRegistrationText,
    taxRegistrationOptionText,
    taxRegistrationOptionNotRegisteredText,
    taxRegistrationOptionJointElectedText,
    remittanceEmailText,
    remittanceFrequencyText,
    editAccountInfoText,
    commaDelimitedText,
    monthlyText,
    quarterlyText,
    annuallyText,
    createNewTenantBtn,
    tenantsTableNameHeading,
    tenantsTableChargerCountHeading,
    tenantTabChargerText,
    overviewText,
    locationText,
    accessText,
    pricingText,
    deploymentText,
    swtchCareText,
    editAccountDetailsText,
    caasText,
    arenaText,
    campgroundText,
    fleetText,
    gasStationText,
    governmentText,
    hospitalText,
    hotelText,
    multiUniResidentialText,
    onStreetText,
    parkingLotText,
    parkingGarageText,
    publicText,
    retailText,
    schoolText,
    singleFamilyResidentialText,
    workplaceText,
    pricingSchemaText,
    tenantStatusText,
    editStatusText,
    inActiveDescriptionText,
    inActiveOption1LeftText,
    inActiveOption1RightText,
    inActiveOption2LeftText,
    inActiveOption2RightText,
    inActiveOption3LeftText,
    inActiveOption3RightText,
    inActiveOption4LeftText,
    inActiveOption4RightText,
    deletedDescriptionText,
    deletedDescriptionOption1LeftText,
    deletedDescriptionOption1RightText,
    partnerIntegrationText,
    partnerText,
    pricingPlanText,
    editTenantStatusText,
    taggingText,
    viewSiteDetailsText,
    accessPlanText,
    chargerSettingsText,
    chargerSettingsSelectedText,
    chargersTabUtilizationRateText,
    chargersTabUtilizationRateDescriptionText,
    chargersTabUptimePercentageText,
    chargersTabUptimePercentageDescriptionText,
    waitlistDescriptionText,
    chargersNotLinkedText,
    chargersNotLinkedTooltipText,
    allAssignedText,
    enabledText,
    enabledTooltipText,
    gracePeriodText,
    gracePeriodTooltipText,
    resetTimeText,
    resetTimeTooltipText,
    chargersTooltipText,
    activeWaitlistText,
    driverNameText,
    positionText,
    snoozeLimitText,
    snoozeLimitTooltipText,
    joinedWaitlistText,
    saveSuccessText,
    saveErrorText,
  }
}
